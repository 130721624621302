$blog__articles-margin             : 0 0 112px !default;
$blog__articles-margin\@medium     : 0 !default;

//articles-list
$blog__articles-list-margin        : 0 0 72px !default;

//article-item
$blog__article-item-padding        : 0 !default;
$blog__article-item-margin         : 0 0 $spacer--semi-medium !default;
$blog__article-item-border         : $border-base !default;
$blog__article-item-border-width   : 0 0 $border-width-base 0 !default;

//aside-heading
$blog__nav-heading-font-size       : $font-size-large !default;
$blog__nav-heading-font-weight     : $font-weight-bold !default;
$blog__nav-heading-margin          : 0 0 $spacer--semi-medium !default;
$blog__nav-heading-text-transform  : none !default;
$blog__nav-heading-height          : 48px !default;

//aside-list-item
$blog__nav-list-item-padding       : 0 !default;
$blog__nav-list-item-margin        : 0 !default;

//aside-list-link
$blog__nav-list-link-text-transform: uppercase;
$blog__nav-list-link-font-size     : $font-size-medium;
$blog__nav-list-link-height        : 48px;

//recommended
$blog__recommended-margin          : 0 0 72px !default;
$blog__recommended-margin\@medium  : 0 0 72px $spacer--medium !default;
$blog__recommended-margin\@large   : 0 0 72px !default;

//categories
$blog__categories-margin           : 0 !default;
$blog__categories-margin\@medium   : 0 0 0 $spacer--medium !default;
$blog__categories-margin\@large    : 0 !default;
