$header__background              : $gray-lighter;
$header__wrapper-padding\@large  : $spacer--semi-medium 0;

$header__buttons-width\@medium        : 192px;

$header__button-width                 : 38px;
$header__button-width\@small          : auto;
$header__button-width\@medium         : 100%;
$header__button-width\@large          : auto;
$header__button-min-width             : 0;
$header__button-min-width\@medium     : 44px;
$header__button-min-width\@large      : 48px;
$header__button-border                : 1px solid $gray-light;
$header__button-padding               : 0;
$header__button-padding\@small        : 0 $spacer--medium;
$header__button-margin\@medium        : 0;
$header__button-margin\@large         : 0 $spacer 0 $spacer--medium;


$header__minicart-border\@medium      : 0;

$header__minicart-button-margin\@large: 0 0 0 $spacer;

$search-form__button-top              : 0;
$search-form__button-height           : 48px;
$search-form__button-height           : 48px;

$search-form__button-border           : 2px solid $color-primary;
$search-form__button-border-width     : 2px;

@import 'header';
@import 'bbq-header';
