$tooltip__icon-size                         : 24px !default;

$tooltip__content-wrapper-z-index           : $z-index-high !default;
$tooltip__content-wrapper-width             : 100% !default;
$tooltip__content-wrapper-min-width         : 100% !default;
$tooltip__content-wrapper-min-width\@medium : 288px !default;
$tooltip__content-wrapper-min-width\@large  : 320px !default;
$tooltip__content-background                : $color-primary !default;
$tooltip__content-color                     : $gray-lighter !default;
$tooltip__content-font-weight               : $font-weight-normal !default;
$tooltip__content-text-transform            : initial !default;
$tooltip__content-wrapper-text-align        : left !default;

$tooltip__after--is-open-background         : $tooltip__content-background !default;

$tooltip__heading-margin                    : $spacer 0 !default;
$tooltip__heading-font-family               : $font-family-base !default;
$tooltip__heading-font-size                 : $font-size-large !default;
$tooltip__heading-font-weight               : $font-weight-bold !default;
$tooltip__heading-text-transform            : initial !default;

$tooltip__trigger-color--text               : $color-primary !default;
$tooltip__trigger-decoration-hover--text    : underline !default;
$tooltip__trigger-bg-color                  : transparent !default;
$tooltip__trigger-icon-fill                 : $color-primary !default;

$tooltip__close-bg-color                    : transparent !default;
$tooltip__close-icon-fill                   : $gray-lighter !default;
