$wishlist-management__item-font-size          : $font-size-base !default;
$wishlist-management__item-font-weight--active: $font-weight-bold !default;

$wishlist-management__list-flex\@medium       : 1 0 50% !default;

$wishlist__grid-padding                       : $spacer--medium 0 $spacer--large 0 !default;

$wishlist__rating-wrapper-min-height          : 16px !default;

$wishlist__item-stock-info-width              : 100% !default;
$wishlist__item-stock-info-order              : 3 !default;

$wishlist__price-padding                      : 0 !default;
$wishlist__price-padding\@medium              : 0 0 $spacer !default;
$wishlist__price-font-weight                  : $font-weight-bold !default;

$wishlist__name-margin                        : $spacer--medium 0 !default;
$wishlist__name-margin\@large                 : $spacer 0 !default;
$wishlist__name-height                        : 48px !default;
$wishlist__name-link-color                    : $gray-darkest !default;
$wishlist__name-link-font-size                : $font-size-base !default;
$wishlist__name-link-font-size\@medium        : $font-size-medium !default;
$wishlist__name-link-font-weight              : $font-weight-normal !default;
$wishlist__name-line-clamp                    : 2 !default;
$wishlist__name-link-line-height              : 1.7 !default;
$wishlist__name-link-line-height\@medium      : 1.5 !default;

$wishlist__item-inner-border                  : $border-base !default;
$wishlist__item-inner-border-width            : 0 0 $border-width-base 0 !default;
$wishlist__inner-padding                      : $spacer 0 !default;
$wishlist__inner-padding\@medium              : 0 !default;

$wishlist__label-margin                       : 0 0 $spacer !default;
$wishlist__label-padding                      : 0 $spacer 0 0 !default;

$wishlist__qty-margin                         : $spacer 0 !default;
$wishlist__qty-margin\@medium                 : $spacer--medium 0 !default;
$wishlist__field-qty-text-align               : center !default;
$wishlist__field-qty-max-width                : 100% !default;
$wishlist__field-qty-max-width                : 80px !default;
$wishlist__field-qty-max-width\@xl            : 166px !default;

$wishlist__quantity-button-width              : 48px !default;
$wishlist__quantity-button-height             : 48px !default;
$wishlist__quantity-button-icon-fill          : $gray-dark !default;
$wishlist__quantity-button-icon-fill--active  : $white !default;
$wishlist__quantity-input-color               : $gray-dark !default;

$wishlist__comment-width                      : 100% !default;

$wishlist__item-actions-margin                : $spacer 0 !default;
$wishlist__item-actions-margin\@medium        : $spacer 0 $spacer--medium !default;
$wishlist__item-actions-order                 : 2 !default;
$wishlist__item-actions-order--to-cart        : 3 !default;
$wishlist__item-actions-width--to-cart        : 100% !default;

$wishlist__to-cart-margin                     : 0 !default;

$wishlist__button-width                       : 100% !default;

$wishlist__actions-padding                    : $spacer--semi-medium 0 0 !default;
$wishlist__buttons-icon-fill                  : $gray-dark !default;
$wishlist__buttons-icon-fill--active          : $white !default;
$wishlist__buttons-link-font-size             : $font-size-medium !default;
$wishlist__buttons-link-line-height           : 2 !default;
$wishlist__buttons-link-letter-spacing        : 1px !default;
$wishlist__actions-button-padding             : 0 $spacer--medium !default;
$wishlist__actions-button-padding\@xl         : 0 $spacer--large !default;
