.price {
    &--between {
        display: flex;
        flex-direction: column;
    }

    &__text {
        font-size: $font-size-base;
    }

    &__value {
        &--between {
            line-height: $font-line-height;
            align-items: flex-end;
            display: inline-flex;

            .price__value--old {
                margin-right: 0;
            }

            .price__text {
                display: none;
            }
        }

        &--old {
            display: none;
        }
    }

    .old-price .price-container {
        display: none;

        &.price-msrp_price {
            display: block;

            span.price-wrapper {
                text-decoration: line-through;
                font-size: .96rem;
                color: black;
                font-weight: normal;
            }
        }
    }
}
