.product-view {
    &__info {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: $gray-dark;
        font-size: $font-size-small;

        @include mq($screen-l) {
            justify-content: flex-start;
        }
    }

    &__manufacturer,
    &__model,
    &__sku {
        margin: 0 0 $spacer--medium;
    }

    &__manufacturer,
    &__model {
        @include mq($screen-l) {
            margin: 0 $spacer--semi-medium $spacer--large 0;
        }
    }

    &__manufacturer-name {
        color: $color-secondary;
        font-weight: $font-weight-bold;
    }

    &__manufacturer-label,
    &__model-label {
        margin: 0 $spacer 0 0;
    }

    &__shipping-info {
        display: inline-flex;
        justify-content: flex-start;
        width: 100%;
        padding: $spacer--medium 0;

        @include mq($screen-m) {
            display: inline-flex;
            width: 50%;
        }
    }

    &__free-shipping {
        color: $green;
        font-size: $font-size-medium;
        text-transform: uppercase;

        &:before {
            @include mq($screen-m) {
                content: "+";
                display: inline-block;
            }
        }
    }

    &__custom-stock-status {
        padding: $spacer 0 0;
        color: $gray-dark;
        font-size: $font-size-small;
        text-align: left;
        border: $border-base;
        border-width: $border-width-base 0 0;

        p {
            margin: 0;
            color: $gray-dark;
            font-size: $font-size-small;
        }
    }

    &__price {
        width: 50%;

        @include mq($screen-m) {
            display: inline-flex;
        }
    }

    .add-to-cart,
    &__price {
        .price__value .old-price .price-container span.price-wrapper {
            text-decoration: line-through;
            font-size: 1.27rem;
            margin-right: 10px;
            color: black;
            font-weight: normal;
        }
    }

    .price__value--old {
        display: none;
    }

    &__main-actions {
        display: flex;
        align-items: flex-end;
    }

    &__buttons-wrapper {
        flex-grow: 1;
    }

    &__buttons {
        margin: 0 0 $spacer--medium;
    }

    &__add-to-cart {
        margin: 0 0 0 $spacer--medium;
    }

    &__yotpo-summary {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__short-description {
        margin-bottom: $spacer--medium;
    }

    &__sku-label {
        display: inline-flex;
        margin-right: $spacer;
    }

    .prop65-img {
        display: inline-flex;
        width: 35px;
        margin: 0 $spacer 0 0;
    }

    .configurable-variation-qty {
        display: none;
    }
}
