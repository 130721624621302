$all-box-sizing                  : border-box !default;

$body-height                     : 100% !default;
$body-min-width                  : 320px !default;
$body-font-family                : $font-family-base !default;
$body-font-weight                : $font-weight-base !default;
$body-font-size                  : $font-size-base !default;
$body-line-height                : $font-line-height !default;
$body-color                      : $font-color-base !default;
$body-scroll-behavior            : smooth !default;
$body-display                    : flex !default;
$body-flex-direction             : column !default;

$page-wrapper-flex               : 1 0 auto !default;
$page-wrapper-margin-bottom      : 64px !default;

$maincontent-margin-top          : $spacer--extra-large !default;
$maincontent-margin-top\@large   : $spacer--semi-large !default;
$maincontent-margin-top--homepage: 0 !default;
$maincontent-margin-top--product : 0 !default;
$maincontent-margin-top--category: 0 !default;

$paragraph-margin                : 0 !default;
$paragraph-padding               : 0 !default;
$description-margin              : 0 !default;
$form-margin                     : 0 !default;
