.product-list-item {
    margin: $spacer--medium 0;

    &__stock-status {
        @extend .product-view__stock;
    }

    &__free-shipping {
        @extend .product-grid-item__free-shipping;

        @include mq($screen-m) {
            text-align: right;
        }
    }

    &__custom-stock-status {
        @extend .product-grid-item__custom-stock-status;
        width: 100%;

        @include mq($screen-m) {
            text-align: right;
        }
    }

    &__price {
        @include mq($screen-m) {
            margin: 0;
        }
    }

    &__stock {
        font-weight: $font-weight-bold;

        @include mq($screen-m) {
            margin: 0 0 $spacer--extra-large;
        }
    }

    &__model {
        @extend .product-list-item__sku;
        margin: 0;
    }

    &__image-link {
        width: 100%;

        .image {
            max-height: 150px;
            width: auto;
        }
    }
}
