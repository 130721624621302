.rewards {
    display: flex;
    justify-content: center;
    min-height: $spacer--semi-medium;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;

    .amasty-rewards-highlight {
        padding-bottom: 0;
    }
}

.amrewards-discount-form {
    max-width: none;
}
