$account-form__padding                              : $spacer 0 0 !default;
$account-form__actions-margin                       : $spacer--medium 0 !default;
$account-form__actions-width                        : 100% !default;
$account-form__actions-width\@medium                : auto !default;

$account-form__button-min-width                     : 208px !default;

$account-form__form-margin                          : 0 !default;

$account-form__fieldset-max-width\@medium           : 432px !default;
$account-form__fieldset-width                       : 100% !default;
$account-form__fieldset-margin                      : 0 0 $spacer--semi-large !default;

$account-form__legend-font-family                   : $font-family-base !default;
$account-form__legend-font-size                     : $font-size-large !default;
$account-form__legend-font-weight                   : $font-weight-bold !default;
$account-form__legend-margin                        : 0 !default;
$account-form__legend-padding                       : 0 0 $spacer--medium 0 !default;
$account-form__legend-width                         : 100% !default;
$account-form__legend-text-transform                : uppercase !default;

$account-form__forgot-password-margin               : $spacer--medium 0 0 0 !default;
$account-form__forgot-password-color                : $gray-darker !default;
$account-form__forgot-password-font-weight          : $font-weight-bold !default;
$account-form__forgot-password-text-decoration      : none !default;
$account-form__forgot-password-text-decoration-hover: underline !default;
$account-form__forgot-password-line-height          : 48px !default;

$account-form__info-width                           : 656px !default;
$account-form__info-margin                          : $spacer--extra-large auto !default;
$account-form__info-font-size                       : $font-size-medium !default;

$account-form__validation-tips-margin               : 0 0 $spacer--semi-medium !default;
$account-form__validation-tips-font-size            : $font-size-medium !default;
