@import 'account-form-variables';

.account-form {
    padding: $account-form__padding;

    &__info {
        max-width: $account-form__info-width;
        margin: $account-form__info-margin;
        font-size: $account-form__info-font-size;
        text-align: center;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $account-form__form-margin;
    }

    &__fieldset {
        width: $account-form__fieldset-width;
        margin: $account-form__fieldset-margin;

        @include mq($screen-m) {
            max-width: $account-form__fieldset-max-width\@medium;
        }
    }

    &__legend {
        margin: $account-form__legend-margin;
        padding: $account-form__legend-padding;
        width: $account-form__legend-width;
        font-family: $account-form__legend-font-family;
        font-size: $account-form__legend-font-size;
        font-weight: $account-form__legend-font-weight;
        text-transform: $account-form__legend-text-transform;
        text-align: center;

        &--hidden {
            @include visually-hidden();
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: $account-form__actions-width;
        margin: $account-form__actions-margin;

        @include mq($screen-m) {
            width: $account-form__actions-width\@medium;
        }
    }

    &__button {
        width: 100%;
        min-width: $account-form__button-min-width;
    }

    &__forgot-password,
    &__link {
        margin: $account-form__forgot-password-margin;
        color: $account-form__forgot-password-color;
        font-weight: $account-form__forgot-password-font-weight;
        text-decoration: $account-form__forgot-password-text-decoration;
        line-height: $account-form__forgot-password-line-height;

        &.focus-visible,
        &:hover {
            text-decoration: $account-form__forgot-password-text-decoration-hover;
        }
    }

    &__validation-tips {
        margin: $account-form__validation-tips-margin;
        font-size: $account-form__validation-tips-font-size;
    }
}
