.compare {
    &__link--image {
        margin-bottom: $spacer--semi-medium;
    }

    &__buttons-container {
        align-items: center;
    }

    &__remove {
        position: absolute;
        top: 0;
        right: 0;

        .compare__remove-icon {
            fill: $red;
        }
    }

    &__label {
        @include mq($screen-m) {
            &--long {
                padding: $spacer $spacer--medium;
            }
        }
    }

}

