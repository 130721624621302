$dashboard-items__padding                : $spacer--medium !default;

$dashboard-items__title-font-family      : $font-family-base !default;
$dashboard-items__title-font-size        : $font-size-large !default;
$dashboard-items__title-font-weight      : $font-weight-bold !default;
$dashboard-items__title-padding          : $spacer--medium !default;
$dashboard-items__title-background       : $bg-color-secondary !default;
$dashboard-items__title-border           : $border-secondary !default;
$dashboard-items__title-border-width     : 0 0 $border-width-secondary !default;
$dashboard-items__title-text-transform   : uppercase !default;

$dashboard-items__subtitle-font-size     : $font-size-medium !default;
$dashboard-items__subtitle-font-weight   : $font-weight-normal !default;
$dashboard-items__subtitle-text-transform: uppercase !default;

$dashboard-items__item-font-size         : $font-size-medium !default;

$dashboard-items__collapsible-transform  : rotate(180deg) !default;

.dashboard-items {
    &__item {
        padding: $dashboard-items__padding;
        font-size: $dashboard-items__item-font-size;
    }

    &__title,
    &__subtitle {
        font-family: $dashboard-items__title-font-family;
    }

    &__title {
        padding: $dashboard-items__title-padding;
        background: $dashboard-items__title-background;
        font-size: $dashboard-items__title-font-size;
        font-weight: $dashboard-items__title-font-weight;
        border: $dashboard-items__title-border;
        border-width: $dashboard-items__title-border-width;
        text-transform: $dashboard-items__title-text-transform;
    }

    &__collapsible {
        &--active {
            .icon {
                transform: $dashboard-items__collapsible-transform;
            }
        }
    }

    &__title-collapsible {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &__subtitle {
        font-size: $dashboard-items__subtitle-font-size;
        font-weight: $dashboard-items__subtitle-font-weight;
        text-transform: $dashboard-items__subtitle-text-transform;
    }
}
