.tab {
    &--secondary {
        @include mq($screen-m) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
        }

        .tab__title {
            &.selected {
                @extend .tab__title--active;
            }
        }
    }
}
