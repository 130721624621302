//
//  Toolbar Styles
//  ____________________________________________

//
//  Variables
//  --------------------------------------------

$amrev-toolbar__active__color: #007bdb;
$amrev-toolbar__filter__border-color: #dcdcdc;
$amrev-toolbar__sorting__border-color: #d6d6d6;

//
//  Common
//  --------------------------------------------

.amrev-toolbar-container {
    & {
        display: flex;
        justify-content: center;
        align-items: center;

        background: $white;
        color: $amreview-color__grey-dark;
        flex-wrap: wrap;
        font-size: 12px;
        justify-content: flex-end;
        padding: 0;
    }

    &.-disabled {
        opacity: .8;
        pointer-events: none;
    }

    &.-all .amrev-sorting {
        border-left: none;
    }

    .amrev-filters {
        display: flex;
        justify-content: center;
        align-items: center;

        flex-basis: 100%;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        order: 2;
        padding: 0;
    }

    .amrev-filter {
        border-bottom: 1px solid $amrev-toolbar__filter__border-color;
        flex-basis: 100%;
        margin: 0;
        padding: $amrev__indent__l 0 $amrev__indent $amrev__indent__xl;
    }

    .amrev-sorting {
        background: $amreview-placeholder__color;
        border: none;
        flex-basis: 100%;
        order: 1;
        padding: $amrev__indent 18px;
    }

    .amrev-sortbtn:before {
        font-size: 14px;
    }

    .amrev-sortbtn:hover:before,
    .amrev-sortbtn:focus:before {
        color: $amrev-toolbar__active__color;
    }

    .amrev-select {
        height: 25px;
        margin: 5px 5px 5px 8px;
        max-width: 160px;
        padding: 0 0 0 10px;
        width: 100%;
    }

    .amrev-label {
        margin: 0;
    }
}
.amreview-toolbar-wrap {
    .toolbar--secondary {
        border: none;
    }
    .toolbar__limiter {
        display: none;
    }
}

//
//  Tablet
//  --------------------------------------------
@media screen and (min-width: 768px) {
    .amrev-toolbar-container {
        & {
            background: $amreview-placeholder__color;
            padding: $amrev__indent / 2 $amrev__indent;
        }

        .amrev-filters {
            flex-basis: auto;
            flex-wrap: nowrap;
            order: 1;
        }

        .amrev-filter {
            border: none;
            flex-basis: auto;
            margin: 0;
            padding: 0;
        }

        .amrev-sorting {
            flex-basis: 300px;
            order: 2;
            padding: 0 0 0 $amrev__indent__l;
        }

        &.-all .amrev-sorting {
            border-left: 1px solid $amrev-toolbar__sorting__border-color;
        }

        .amrev-label {
            margin: 0 $amrev__indent__l / 2;
        }

        .amrev-select {
            max-width: 175px;
        }
    }
}

//
//  Desktop +
//  --------------------------------------------
@media screen and (min-width: 1024px) {
    .amrev-toolbar-container {
        margin: $amrev__indent__xl 0 $amrev__indent__xxl 0;
    }
}
