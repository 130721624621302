.get_compare_list_bar {
    width: 100%;
    max-width: $max-content-width;

    .comparelist {
        display: block;
    }

    .compare {
        @extend .button;

        margin: 0;
    }

    .comparisonBar {
        z-index: calc(#{$z-index-high} - 2);
        width: 100%;
        max-width: inherit;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;

        .compare_header {
            background-color: $color-secondary;
            border-color: $color-secondary;

            .title {
                display: flex;
                align-items: center;

                .numberOfItems {
                    color: $color-secondary;
                }

                .fa {
                    margin: 0;
                }

                .fa-long-arrow-up {
                    margin-left: auto;

                    &::before {
                        content: url("../images/icons/angle-up.svg");
                    }
                }
            }
        }

        .inner {
            background-color: $white;
            border: $border-width-base $border-style-base $border-color-base;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: $spacer--small;

            @include mq($screen-m) {
                flex-direction: row;
                padding: $spacer--small $spacer--semi-large;
            }

            @include mq($screen-xl) {
                gap: $spacer--semi-medium; // sass-lint:disable-line no-misspelled-properties
            }

            .comparison_selectedproducts {
                width: 100%;
                max-height: 450px;
                display: block;
                overflow-x: auto;
                white-space: nowrap;

                @include mq($screen-m) {
                    flex: 0.7;
                    padding-bottom: $spacer--small;
                }

                @include mq($screen-xl) {
                    flex: 0.8;
                }

                tbody {
                    tr {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: $spacer--small; // sass-lint:disable-line no-misspelled-properties

                        @include mq($screen-m) {
                            flex-direction: row;
                            gap: $spacer--semi-medium; // sass-lint:disable-line no-misspelled-properties
                        }

                        @include mq($screen-l) {
                            gap: $spacer--large; // sass-lint:disable-line no-misspelled-properties
                        }

                        @include mq($screen-xl) {
                            gap: $spacer--semi-medium; // sass-lint:disable-line no-misspelled-properties
                        }

                        td {
                            min-width: 140px;
                            width: 100%;
                            padding: 0 $spacer--small;

                            @include mq($screen-m) {
                                padding: 0;
                                width: auto;
                            }
                        }
                    }
                }

                .comparisonProductBox {
                    margin-right: 0;
                    width: 100%;
                    padding-top: $spacer--medium;
                    transition: $transition-base;

                    &:hover {
                        border-color: $color-secondary;
                    }

                    .product_name {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 85%;
                        white-space: nowrap;
                        position: absolute;
                        left: 50%;
                        bottom: -8px;
                        transform: translateX(-50%);

                        @include mq($screen-m) {
                            bottom: $spacer--extra-small;
                        }

                    }
                }
            }

            .delete {
                span {
                    &::before {
                        content: url("../images/icons/close--orange.svg");
                    }
                }
            }

            .info {
                position: initial;
                float: initial;
                margin: $spacer--semi-medium 0 $spacer--medium 0;

                @include mq($screen-m) {
                    margin: 0;
                    flex: 0.3;
                }

                @include mq($screen-xl) {
                    flex: 0.2;
                }
            }
        }

        .primary {
            @include mq($screen-m) {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.page-layout-1column {
    .get_compare_list_bar {
        .comparisonBar {
            left: 50%;
        }
    }
}

.page-with-filter {
    .get_compare_list_bar {
        max-width: 1130px;

        .comparisonBar {
            @include mq($screen-l) {
                left: initial;
                transform: initial;
            }
        }
    }
}
