.brief-info {
    &__link {
        &:hover,
        &.focus-visible {
            background: transparent;

            &:before {
                display: block;
                background-color: $color-primary;
            }
        }
    }

    &--dividers {
        display: block;

        .brief-info__item-link {
            display: flex;
            flex-grow: 1;
            padding: $spacer;

            @include mq($screen-m) {
                flex-direction: column;
                padding: $spacer $spacer--medium;
            }

            @include mq($screen-l) {
                flex-direction: row;
            }

            &:hover,
            &.focus-visible {
                background-color: $gray-lighter;
                text-decoration: none;
            }
        }

        .brief-info__item {
            border-bottom: $border-base;

            @include mq($screen-m) {
                border: none;
                padding: 0 $spacer--semi-medium;
                margin: 0;
            }

            &:not(:last-child) {
                &:after {
                    display: none;

                    @include mq($screen-m) {
                        display: block;
                    }
                }
            }
        }

        .brief-info__icon-wrapper {
            @include mq($screen-m) {
                margin: 0 $spacer--medium 0 0;
            }
        }
    }
}
