$product-grid-item__width                                   : 100% !default;
$product-grid-item__padding                                 : 0 !default;
$product-grid-item__border                                  : 1px solid $gray-light !default;
$product-grid-item__border-width                            : 0 0 1px 0 !default;
$product-grid-item__transition                              : $transition-base !default;

$product-grid-item__badges-top-position                     : $spacer--medium !default;
$product-grid-item__badges-left-position                    : 0 !default;
$product-grid-item__badges-z-index                          : $z-index-low !default;

$product-grid-item__image-margin                            : 0 auto !default;
$product-grid-item__image-width                             : auto !default;
$product-grid-item__image-width--lazyload                   : 100% !default;
$product-grid-item__image-height--lazyload                  : auto !default;
$product-grid-item__image-height                            : auto !default;

$product-grid-item__details-padding                         : 0 $spacer !default;
$product-grid-item__details-padding\@xl                     : 0 $spacer--semi-medium !default;
$product-grid-item__details-margin                          : $spacer 0 $spacer--medium !default;
$product-grid-item__details-border                          : $border-base !default;
$product-grid-item__details-border-width                    : 0 !default;

$product-grid-item__name-color                              : $font-color-base !default;
$product-grid-item__name-color-hover                        : $font-color-base !default;
$product-grid-item__name-font-size                          : $font-size-base !default;
$product-grid-item__name-font-size\@medium                  : $font-size-medium !default;
$product-grid-item__name-font-weight                        : $font-weight-normal !default;
$product-grid-item__name-line-height                        : 1.5 !default;
$product-grid-item__name-text-decoration                    : none !default;
$product-grid-item__name-text-decoration-hover              : none !default;
$product-grid-item__name-margin                             : 0 0 $spacer !default;
$product-grid-item__name-height                             : 65px !default;
$product-grid-item__name-height\@small                      : 45px !default;

$product-grid-item__rating-margin-bottom                    : $spacer !default;
$product-grid-item__rating-height                           : $spacer--medium !default;
$product-grid-item__price-label-font-weight                 : $font-weight-normal !default;
$product-grid-item__price-old-font-size                     : $font-size-base !default;
$product-grid-item__price-old-font-size\@medium             : $font-size-medium !default;
$product-grid-item__price-min-height                        : 44px !default;

$product-gird-item__primary-margin                          : 0 0 0 $spacer !default;

$product-grid-item__action-wishlist-background              : transparent !default;

$product-grid-item__IE-margin                               : 0 $spacer $spacer--medium $spacer !default;
$product-grid-item__IE-width                                : calc(50% - #{$spacer--medium} - 1px) !default;
$product-grid-item__IE-width\@medium                        : calc(100% / 3 - #{$spacer--medium} - 1px) !default;

// wishlist variant
$product-grid-item__action-margin--wishlist                 : $spacer 0 0 0 !default;

$product-grid-item__comment-input-width--wishlist           : 100% !default;
$product-grid-item__comment-margin--wishlist                : 0 0 $spacer 0 !default;
$product-grid-item__comment-margin--wishlist\@medium        : 0 0 $spacer--medium 0 !default;

$product-grid-item__qty-input-width--wishlist               : 72px !default;
$product-grid-item__qty-input-width--wishlist\@xl           : 60px !default;
$product-grid-item__qty-button-size--wishlist               : 48px !default;
$product-grid-item__qty-input-border-width--wishlist        : 1px !default;
$product-grid-item__qty-input-border-width--wishlist\@xl    : 1px 0 !default;
$product-grid-item__qty-width--wishlist                     : 100% !default;
$product-grid-item__qty-width--wishlist\@medium             : 80px !default;
$product-grid-item__qty-width--wishlist\@xl                 : 156px !default;

$product-grid-item__primary-action-width--wishlist          : 100% !default;
$product-grid-item__primary-action-margin--wishlist         : $spacer 0 0 0 !default;
$product-grid-item__primary-action-margin--wishlist\@medium : $spacer--medium 0 0 0 !default;
$product-grid-item__primary-action-button-padding--wishlist : 0 $spacer--semi-medium !default;

$product-grid-item__secondary-action-width--wishlist        : 100% !default;
$product-grid-item__secondary-action-width--wishlist\@medium: calc(100% - #{$product-grid-item__qty-width--wishlist\@medium}) !default;
$product-grid-item__secondary-action-width--wishlist\@xl    : calc(100% - #{$product-grid-item__qty-width--wishlist\@xl}) !default;

$product-grid-item__tocompare-background-color              : $gray-lighter !default;

// Slider item variant
$product-grid-item__image-wrapper-width--slider-item        : 100% !default;

// EE Version
$product-grid-item-ee__actions-width                        : 100% !default;
$product-grid-item-ee__tocart-width                         : 48px !default;
$product-grid-item-ee__tocart-height                        : 48px !default;
$product-grid-item-ee__requisition-margin                   : 0 $spacer 0 0 !default;
$product-grid-item-ee__multiwishlist-width                  : 48px !default;
$product-grid-item-ee__multiwishlist-margin                 : 0 $spacer !default;
$product-grid-item-ee__dropdown-list-content-width          : 180px !default;
$product-grid-item-ee__dropdown-list-content-width\@medium  : 210px !default;
$product-grid-item-slider__dropdown-list-content-top        : auto !default;
$product-grid-item-slider__dropdown-list-content-bottom     : 100% !default;
$product-grid-item-ee__dropdown-list-label-width            : 48px !default;
$product-grid-item-ee__dropdown-list-label-height           : 48px !default;
