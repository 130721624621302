$company__tree-anchor-padding       : $spacer !default;
$company__tree-anchor-transition    : $transition-base !default;
$company__tree-anchor-bg-selected   : $color-primary !default;
$company__tree-anchor-color-selected: $white !default;

.company {
    &__tree {
        position: relative;

        .loader__icon {
            position: relative;
        }
    }

    .jstree-container-ul {
        @extend .list;
    }

    .jstree-node {
        @extend .list__item;

        .jstree-anchor {
            display: inline-block;
            padding: $company__tree-anchor-padding;
            transition: $company__tree-anchor-transition;

            &.jstree-clicked {
                background-color: $company__tree-anchor-bg-selected;
                color: $company__tree-anchor-color-selected;
            }
        }
    }
}
