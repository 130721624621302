@import '404-variables';

.not-found {
    padding: $not-found__padding;
    max-width: $not-found__max-width;
    margin: $not-found__margin;

    @include mq($screen-l) {
        max-width: $not-found__max-width\@large;
    }

    &__heading {
        font-weight: $not-found__heading-font-weight;
        margin: $not-found__heading-margin;
    }

    &__heading-number {
        display: block;
        letter-spacing: $not-found__number-letter-spacing;
        font-family: $not-found__number-font-family;
        font-weight: $not-found__number-font-weight;
        font-size: $not-found__number-font-size;
        line-height: $not-found__number-line-height;
        margin: $not-found__number-margin;

        @include mq($screen-m) {
            font-size: $not-found__number-font-size\@medium;
            line-height: $not-found__number-line-height\@medium;
            letter-spacing: $not-found__number-letter-spacing\@medium;
        }
    }

    &__actions {
        margin: $not-found__actions-margin;

        @include mq($screen-l) {
            margin: $not-found__actions-margin\@large;
        }
    }

    &__link {
        width: $not-found__link-width;
        margin: $not-found__link-margin;
        max-width: $not-found__link-max-width;
    }

    &__text {
        color: $not-found__text-color;
        font-size: $not-found__text-font-size;
        line-height: $not-found__text-line-height;

        p {
            @extend .not-found__text;
        }
    }
}
