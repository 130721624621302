.list {
    &--description {
        .list__label {
            font-size: $font-size-extra-small;
            font-weight: $font-weight-bold;
            padding-bottom: 0;
            line-height: 1.33;

            @include mq($screen-m) {
                font-size: $font-size-small;
            }
        }

        .list__value {
            font-size: $font-size-extra-small;
            padding-bottom: 0;
            line-height: 1.33;

            @include mq($screen-m) {
                font-size: $font-size-small;
            }
        }
    }
}
