.checkout-shipping-method {
    .ampickup {
        &-store {
            flex-grow: 1;
            width: auto;
            margin-bottom: 0;
            max-height: 48px;

            &.select {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &-button {
            background-color: $gray-darkest;
        }

        &-checkbox {
            label {
                font-size: $font-size-base;
            }
        }
        &-conditions {
            p {
                font-size: $font-size-base;
            }
        }
        &-details {
            .amlocator-link {
                font-weight: $font-weight-bold;
                text-decoration: none;
                font-size: 20px;
                display: inline-block;
            }
            p {
                font-size: $font-size-base;
            }
        }

        .admin__control-textarea {
            @extend .input__field;
            @extend .input__field--textarea;
        }

        &-field {
            &.-date {
                .date {
                    width: 100%;
                    .ui-datepicker-trigger {
                        max-width: none;
                        cursor: pointer;
                    }
                }
            }

            &.-time {
                .select__field {
                    padding-top: 0;
                    padding-bottom: 0;
                    max-height: 48px;
                }
            }
        }
    }
    .selected-store {
        display: grid;
        @media screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        .amlocator-today {
            display: block;
            @media screen and (max-width: 767px) {
                margin: 20px 0;
            }
        }
    }
    .pickup-notice-message {
        display: block;
        padding-top: 15px;
    }
}
.amlocator-online-only {
    padding: 1em;
    background-color: $gray-light;
}
label.error {
    color: $red;
}