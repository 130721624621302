$minicart-product__padding                    : $spacer--medium 0 !default;
$minicart-product__border                     : $border-base !default;
$minicart-product__spacer                     : $spacer !default;

$minicart-product__image-width                : auto !default;
$minicart-product__image-max-width            : 100% !default;
$minicart-product__image-flex-basis           : 78px !default;

$minicart-product__data-padding               : 0 0 0 $spacer--medium !default;

$minicart-product--info-margin                : 0 0 $spacer !default;

$minicart-product__name-line-height           : 24px !default;
$minicart-product__name-font-size             : $font-size-base !default;
$minicart-product__name-text-transform        : none !default;

$minicart-product__link-text-decoration       : none !default;
$minicart-product__link-font-weight             : $font-weight-base !default;
$minicart-product__link-color                   : $color-primary !default;

$minicart-product__attributes-button-margin     : 0 !default;
$minicart-product__attributes-button-padding    : 0 !default;
$minicart-product__attributes-button-color      : $blue !default;
$minicart-product__attributes-button-bg-color   : $white !default;
$minicart-product__attributes-button-font-size  : $font-size-small !default;

$minicart-product__attributes-color             : $gray-dark !default;
$minicart-product__attributes-font-size         : $font-size-small !default;
$minicart-product__attributes-line-height       : 24px !default;
$minicart-product__attributes-label-padding     : 0 $spacer 0 0 !default;
$minicart-product__attributes-label-font-weight : $font-weight-bold !default;
$minicart-product__attributes-value-padding     : 0 !default;
$minicart-product__attributes-list-padding      : 0 !default;
$minicart-product__attributes-list-icon-margin  : 0 0 0 $spacer !default;

$minicart-product__price-font-size              : $font-size-base !default;
$minicart-product__price-font-weight            : $font-weight-bold !default;
$minicart-product__price-font-size--old         : $font-size-small !default;
$minicart-product__price-align                  : center !default;

$minicart-product__action-icon-color            : $color-secondary !default;
$minicart-product__action-icon-color--active    : $white !default;

$minicart-product__field-max-width              : 56px !default;

$minicart-product__qty-margin                   : 0 0 $spacer !default;
$minicart-product__qty-margin\@medium           : 0 !default;

$minicart-product__update-btn-padding           : 0 $spacer !default;
$minicart-product__update-btn-padding\@medium   : 0 $spacer--large !default;
