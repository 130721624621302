.slider {
    &__container {
        max-width: 100%;
    }

    &--brands {
        .slider__item {
            max-width: none;

            @include mq($screen-m) {
                max-width: none;
            }

            @include mq($screen-l) {
                max-width: none;
            }

            @include mq($max-content-width) {
                max-width: none;
            }
        }
    }
}
