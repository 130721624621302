// mixin for button icon filled on hover
@mixin button-icon-fill($icon-path) {
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: auto;
        mask: url($icon-path) $button__icon-after-mask-repeat--icon-fill $button__icon-after-mask-position--icon-fill;
        background-size: $button__icon-after-bg-size--icon-fill;
        background-image: $button__icon-after-bg-image--icon-fill;
        background-position: $button__icon-after-bg-position--icon-fill;
        transition: $button__icon-after-transition--icon-fill;
        opacity: $button__icon-after-oapcity--icon-fill;
        z-index: $button__icon-after-z-index--icon-fill;
    }
}

@mixin button-fill-bg() {
    .icon {
        fill: $button__icon-fill-hover--icon-fill;
    }
    &:after {
        display: block;
        background-position: $button__icon-after-bg-position-hover--icon-fill;
    }
}
