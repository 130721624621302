$banner__content-padding                : $spacer--medium 0 $spacer--small;
$banner__content-padding\@medium        : $banner__content-padding;
$banner__content-after-min-width        : 48px;
$banner__content-after-background       : $color-secondary;

$banner__button-collapse-after-bg       : $color-secondary;
$banner__button-collapse-before-bg-hover: $color-secondary;

$banner__text-font-weight               : $font-weight-semi-bold;
$banner__text-text-transform            : none;
$banner__text-font-size\@medium         : $font-size-large $font-size-base;

@import 'banner';
@import 'bbq-banner';
