@import 'cms-page-variables';

.cms-page {
    &__content {
        max-width: $cms-page__max-width;
        margin: $cms-page__margin;

        @include mq($screen-m) {
            max-width: $cms-page__max-width\@medium;
        }

        @include mq($screen-l) {
            max-width: $cms-page__max-width\@large;
        }

        @include mq($screen-xl) {
            max-width: $cms-page__max-width\@xlarge;
        }

        .cms-contact &,
        .cms-contact-us & {
            max-width: $cms-contact__max-width;
        }
    }
}
