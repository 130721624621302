$cms-page__margin\@medium: 0 $spacer--semi-large;
$cms-page__margin\@large : 0 auto;

.cms-page {
    &__content {
        @include mq($screen-m) {
            margin: $cms-page__margin\@medium;
        }

        @include mq($screen-l) {
            margin: $cms-page__margin\@large;
        }
    }
}
