//
//  Colors
//  --------------------------------------------

$amreview-stars__color: #b1aeae;
$amreview-stars__filtering__color: #ed9d00;
$amreview-text__color: #767676;
$amreview-link__color: #007acc; // ADA compliant with #292929 main text and #fff background
$amreview-label__color: $amreview-text__color;
$amreview-line__color: $amreview-text__color;
$amreview-button__color: #1979c3;
$amreview-recommended__color: #1ab900;
$amreview-recommended__background: #f1f1f1;
$amreview-input-text__color: #292929;
$amreview-review-bar__active__background: #ed9d00;
$amreview-review-form__background: #f9f9f9;
$amreview-pros__color: #3e7405;
$amreview-cons__color: #d0021b;
$amreview-placeholder__color: #fafafa;
$amreview-summary__bar__background: #dfdfdf;
$amreview-summary__details__color: #748593;
$amreview-author__verified__color: #909090;
$amreview-author__recommended__color: #6b6b6b;
$amreview-author__recommended__border-color: #1ab900;
$amreview-slick__dots__color: #b3b3b3;
$amreview-page__item__color: #ececec;

$amreview-color__grey-dark: #363636;
$amreview-color__blue-light: #6b8fab;
$color-gray94: #f0f0f0;
$color-gray68: #adadad;
$color-gray_light: #d3d3d3;
//
//  Indents
//  --------------------------------------------

$amrev__indent: 10px;
$amrev__indent__l: $amrev__indent + $amrev__indent / 2;
$amrev__indent__xl: $amrev__indent * 2;
$amrev__indent__xxl: $amrev__indent__xl + $amrev__indent / 2;

//
//  Icons
//  --------------------------------------------

$amreview-arrow-down__background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHBhdGggZD0iTTEyMS4zIDM0LjZjLTEuNi0xLjYtNC4yLTEuNi01LjggMGwtNTEgNTEuMS01MS4xLTUxLjFjLTEuNi0xLjYtNC4yLTEuNi01LjggMC0xLjYgMS42LTEuNiA0LjIgMCA1LjhsNTMuOSA1My45Yy44LjggMS44IDEuMiAyLjkgMS4yIDEgMCAyLjEtLjQgMi45LTEuMmw1My45LTUzLjljMS43LTEuNiAxLjctNC4yLjEtNS44eiIgZmlsbD0iIzE5NzljMyIvPjwvc3ZnPg==);
$amreview-form-check__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4wNzIuMzQzYy41MTMuNDg2LjU0OSAxLjMwOS4wOCAxLjgzOEw2LjY3IDkuNTA1YTEuMjI2IDEuMjI2IDAgMCAxLTEuNzQuMTEgMS4yNjIgMS4yNjIgMCAwIDEtLjIwOS0uMjA1TC44MDQgNC41OGExLjMzIDEuMzMgMCAwIDEgLjE2LTEuODMgMS4yMjUgMS4yMjUgMCAwIDEgMS43NjguMTU1TDUuNzc1IDYuNjYgMTEuMjk3LjQyYTEuMjI3IDEuMjI3IDAgMCAxIDEuNzc1LS4wNzd6IiBmaWxsPSIjMUFCOTAwIi8+PC9zdmc+);
$amreview-verified__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgxMnYxMkgweiIvPjxjaXJjbGUgY3g9IjYiIGN5PSI2IiByPSI2IiBmaWxsPSIjQzJFQUJDIi8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04Ljk3OCAzLjdhLjY3LjY3IDAgMCAxIC4wNC45MTVMNS44NCA4LjI2M2EuNTk0LjU5NCAwIDAgMS0uODUxLjA1NS42MjIuNjIyIDAgMCAxLS4xMDMtLjEwMmwtMS45Mi0yLjQwOGEuNjY5LjY2OSAwIDAgMSAuMDc5LS45MS41OTMuNTkzIDAgMCAxIC44NjUuMDc2bDEuNDkzIDEuODcxIDIuNzA2LTMuMTA3YS41OTQuNTk0IDAgMCAxIC44NjktLjAzN3oiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);

$amreview-like__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYxOEgweiIvPjxwYXRoIGQ9Ik0zLjg5NiA3LjI4NkguNTU2QS41NTQuNTU0IDAgMCAwIDAgNy44NDh2OC45ODVjMCAuMzE0LjI0NS41NjEuNTU3LjU2MWgzLjM0YS41NTMuNTUzIDAgMCAwIC41NTYtLjU2MVY3Ljg0OGEuNTUzLjU1MyAwIDAgMC0uNTU3LS41NjJ6TTIwIDguMzVjMC0uOTE1LS42MjMtMS44NC0xLjgxMy0xLjg0aC01LjM5MmMuNzctMS4zOTEuOTk3LTMuMzQ3LjQ2Mi00Ljc3OEMxMi44NjMuNjggMTIuMTEuMDY2IDExLjEzOS4wMDRsLS4wMTYtLjAwMkExLjE3IDEuMTcgMCAwIDAgOS44ODkgMS4wOEM5Ljc1MSAyLjUgOS4xMzQgNS4wMSA4LjI1MSA1LjljLS43NDQuNzUtMS4zOCAxLjA2NS0yLjQzNiAxLjU4Ni0uMTUyLjA3NS0uMzIuMTU3LS40OTYuMjQ2YTEuNCAxLjQgMCAwIDEgLjAwNS4xMTd2OC44OTNsLjM3Ny4xM0M3LjQ0MyAxNy40NzggOC45NDggMTggMTEuMjQ4IDE4aDQuMzZjMS4xOSAwIDEuODEyLS45MjYgMS44MTItMS44NDFhMiAyIDAgMCAwLS4xNjMtLjc5MyAxLjcxOCAxLjcxOCAwIDAgMCAxLS41NTljLjI5Mi0uMzM0LjQ1My0uNzc5LjQ1My0xLjI1MSAwLS4yNy0uMDU1LS41NDMtLjE2My0uNzkyLjk1Ni0uMTY2IDEuNDUzLS45OTMgMS40NTMtMS44MSAwLS40NzUtLjE2OC0uOTUzLS40OTQtMS4zMDIuMzI2LS4zNS40OTQtLjgyNy40OTQtMS4zMDJ6IiBmaWxsPSIjQzRDNEM0Ii8+PC9zdmc+);
$amreview-like__hover__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYxOEgweiIvPjxwYXRoIGQ9Ik0zLjg5NiA3LjI4NkguNTU2QS41NTQuNTU0IDAgMCAwIDAgNy44NDh2OC45ODVjMCAuMzE0LjI0NS41NjEuNTU3LjU2MWgzLjM0YS41NTMuNTUzIDAgMCAwIC41NTYtLjU2MVY3Ljg0OGEuNTUzLjU1MyAwIDAgMC0uNTU3LS41NjJ6TTIwIDguMzVjMC0uOTE1LS42MjMtMS44NC0xLjgxMy0xLjg0aC01LjM5MmMuNzctMS4zOTEuOTk3LTMuMzQ3LjQ2Mi00Ljc3OEMxMi44NjMuNjggMTIuMTEuMDY2IDExLjEzOS4wMDRsLS4wMTYtLjAwMkExLjE3IDEuMTcgMCAwIDAgOS44ODkgMS4wOEM5Ljc1MSAyLjUgOS4xMzQgNS4wMSA4LjI1MSA1LjljLS43NDQuNzUtMS4zOCAxLjA2NS0yLjQzNiAxLjU4Ni0uMTUyLjA3NS0uMzIuMTU3LS40OTYuMjQ2YTEuNCAxLjQgMCAwIDEgLjAwNS4xMTd2OC44OTNsLjM3Ny4xM0M3LjQ0MyAxNy40NzggOC45NDggMTggMTEuMjQ4IDE4aDQuMzZjMS4xOSAwIDEuODEyLS45MjYgMS44MTItMS44NDFhMiAyIDAgMCAwLS4xNjMtLjc5MyAxLjcxOCAxLjcxOCAwIDAgMCAxLS41NTljLjI5Mi0uMzM0LjQ1My0uNzc5LjQ1My0xLjI1MSAwLS4yNy0uMDU1LS41NDMtLjE2My0uNzkyLjk1Ni0uMTY2IDEuNDUzLS45OTMgMS40NTMtMS44MSAwLS40NzUtLjE2OC0uOTUzLS40OTQtMS4zMDIuMzI2LS4zNS40OTQtLjgyNy40OTQtMS4zMDJ6IiBmaWxsPSIjQzJFQUJDIi8+PC9zdmc+);
$amreview-like__active__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyMHYxOEgweiIvPjxwYXRoIGQ9Ik0zLjg5NiA3LjI4NkguNTU2QS41NTQuNTU0IDAgMCAwIDAgNy44NDh2OC45ODVjMCAuMzE0LjI0NS41NjEuNTU3LjU2MWgzLjM0YS41NTMuNTUzIDAgMCAwIC41NTYtLjU2MVY3Ljg0OGEuNTUzLjU1MyAwIDAgMC0uNTU3LS41NjJ6TTIwIDguMzVjMC0uOTE1LS42MjMtMS44NC0xLjgxMy0xLjg0aC01LjM5MmMuNzctMS4zOTEuOTk3LTMuMzQ3LjQ2Mi00Ljc3OEMxMi44NjMuNjggMTIuMTEuMDY2IDExLjEzOS4wMDRsLS4wMTYtLjAwMkExLjE3IDEuMTcgMCAwIDAgOS44ODkgMS4wOEM5Ljc1MSAyLjUgOS4xMzQgNS4wMSA4LjI1MSA1LjljLS43NDQuNzUtMS4zOCAxLjA2NS0yLjQzNiAxLjU4Ni0uMTUyLjA3NS0uMzIuMTU3LS40OTYuMjQ2YTEuNCAxLjQgMCAwIDEgLjAwNS4xMTd2OC44OTNsLjM3Ny4xM0M3LjQ0MyAxNy40NzggOC45NDggMTggMTEuMjQ4IDE4aDQuMzZjMS4xOSAwIDEuODEyLS45MjYgMS44MTItMS44NDFhMiAyIDAgMCAwLS4xNjMtLjc5MyAxLjcxOCAxLjcxOCAwIDAgMCAxLS41NTljLjI5Mi0uMzM0LjQ1My0uNzc5LjQ1My0xLjI1MSAwLS4yNy0uMDU1LS41NDMtLjE2My0uNzkyLjk1Ni0uMTY2IDEuNDUzLS45OTMgMS40NTMtMS44MSAwLS40NzUtLjE2OC0uOTUzLS40OTQtMS4zMDIuMzI2LS4zNS40OTQtLjgyNy40OTQtMS4zMDJ6IiBmaWxsPSIjMUFCOTAwIi8+PC9zdmc+);
$amreview-dislike__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMThoMjBWMEgweiIvPjxwYXRoIGQ9Ik0zLjg5NiAxMC43MTRILjU1NkEuNTU0LjU1NCAwIDAgMSAwIDEwLjE1MlYxLjE2N0MwIC44NTMuMjQ1LjYwNi41NTcuNjA2aDMuMzRjLjMxMSAwIC41NTYuMjQ3LjU1Ni41NjF2OC45ODVhLjU1My41NTMgMCAwIDEtLjU1Ny41NjJ6TTIwIDkuNjVjMCAuOTE1LS42MjMgMS44NC0xLjgxMyAxLjg0aC01LjM5MmMuNzcgMS4zOTEuOTk3IDMuMzQ3LjQ2MiA0Ljc3Ny0uMzk0IDEuMDUzLTEuMTQ2IDEuNjY3LTIuMTE4IDEuNzNoLS4wMTZhMS4xNyAxLjE3IDAgMCAxLTEuMjM0LTEuMDc2Yy0uMTM4LTEuNDItLjc1NS0zLjkzLTEuNjM4LTQuODItLjc0NC0uNzUtMS4zOC0xLjA2NS0yLjQzNi0xLjU4Ni0uMTUyLS4wNzUtLjMyLS4xNTctLjQ5Ni0uMjQ2YTEuNCAxLjQgMCAwIDAgLjAwNS0uMTE3VjEuMjZsLjM3Ny0uMTNDNy40NDMuNTIyIDguOTQ4IDAgMTEuMjQ4IDBoNC4zNmMxLjE5IDAgMS44MTIuOTI2IDEuODEyIDEuODQxYTIgMiAwIDAgMS0uMTYzLjc5M2MuMzk3LjA3Mi43NDMuMjY0IDEgLjU1OS4yOTIuMzM0LjQ1My43NzkuNDUzIDEuMjUxIDAgLjI3LS4wNTUuNTQzLS4xNjMuNzkyLjk1Ni4xNjYgMS40NTMuOTkzIDEuNDUzIDEuODEgMCAuNDc1LS4xNjguOTUyLS40OTQgMS4zMDIuMzI2LjM1LjQ5NC44MjcuNDk0IDEuMzAyeiIgZmlsbD0iI0M0QzRDNCIvPjwvc3ZnPg==);
$amreview-dislike__hover__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMThoMjBWMEgweiIvPjxwYXRoIGQ9Ik0zLjg5NiAxMC43MTRILjU1NkEuNTU0LjU1NCAwIDAgMSAwIDEwLjE1MlYxLjE2N0MwIC44NTMuMjQ1LjYwNi41NTcuNjA2aDMuMzRjLjMxMSAwIC41NTYuMjQ3LjU1Ni41NjF2OC45ODVhLjU1My41NTMgMCAwIDEtLjU1Ny41NjJ6TTIwIDkuNjVjMCAuOTE1LS42MjMgMS44NC0xLjgxMyAxLjg0aC01LjM5MmMuNzcgMS4zOTEuOTk3IDMuMzQ3LjQ2MiA0Ljc3Ny0uMzk0IDEuMDUzLTEuMTQ2IDEuNjY3LTIuMTE4IDEuNzNoLS4wMTZhMS4xNyAxLjE3IDAgMCAxLTEuMjM0LTEuMDc2Yy0uMTM4LTEuNDItLjc1NS0zLjkzLTEuNjM4LTQuODItLjc0NC0uNzUtMS4zOC0xLjA2NS0yLjQzNi0xLjU4Ni0uMTUyLS4wNzUtLjMyLS4xNTctLjQ5Ni0uMjQ2YTEuNCAxLjQgMCAwIDAgLjAwNS0uMTE3VjEuMjZsLjM3Ny0uMTNDNy40NDMuNTIyIDguOTQ4IDAgMTEuMjQ4IDBoNC4zNmMxLjE5IDAgMS44MTIuOTI2IDEuODEyIDEuODQxYTIgMiAwIDAgMS0uMTYzLjc5M2MuMzk3LjA3Mi43NDMuMjY0IDEgLjU1OS4yOTIuMzM0LjQ1My43NzkuNDUzIDEuMjUxIDAgLjI3LS4wNTUuNTQzLS4xNjMuNzkyLjk1Ni4xNjYgMS40NTMuOTkzIDEuNDUzIDEuODEgMCAuNDc1LS4xNjguOTUyLS40OTQgMS4zMDIuMzI2LjM1LjQ5NC44MjcuNDk0IDEuMzAyeiIgZmlsbD0iI0ZGQkNCQyIvPjwvc3ZnPg==);
$amreview-dislike__active__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMThoMjBWMEgweiIvPjxwYXRoIGQ9Ik0zLjg5NiAxMC43MTRILjU1NkEuNTU0LjU1NCAwIDAgMSAwIDEwLjE1MlYxLjE2N0MwIC44NTMuMjQ1LjYwNi41NTcuNjA2aDMuMzRjLjMxMSAwIC41NTYuMjQ3LjU1Ni41NjF2OC45ODVhLjU1My41NTMgMCAwIDEtLjU1Ny41NjJ6TTIwIDkuNjVjMCAuOTE1LS42MjMgMS44NC0xLjgxMyAxLjg0aC01LjM5MmMuNzcgMS4zOTEuOTk3IDMuMzQ3LjQ2MiA0Ljc3Ny0uMzk0IDEuMDUzLTEuMTQ2IDEuNjY3LTIuMTE4IDEuNzNoLS4wMTZhMS4xNyAxLjE3IDAgMCAxLTEuMjM0LTEuMDc2Yy0uMTM4LTEuNDItLjc1NS0zLjkzLTEuNjM4LTQuODItLjc0NC0uNzUtMS4zOC0xLjA2NS0yLjQzNi0xLjU4Ni0uMTUyLS4wNzUtLjMyLS4xNTctLjQ5Ni0uMjQ2YTEuNCAxLjQgMCAwIDAgLjAwNS0uMTE3VjEuMjZsLjM3Ny0uMTNDNy40NDMuNTIyIDguOTQ4IDAgMTEuMjQ4IDBoNC4zNmMxLjE5IDAgMS44MTIuOTI2IDEuODEyIDEuODQxYTIgMiAwIDAgMS0uMTYzLjc5M2MuMzk3LjA3Mi43NDMuMjY0IDEgLjU1OS4yOTIuMzM0LjQ1My43NzkuNDUzIDEuMjUxIDAgLjI3LS4wNTUuNTQzLS4xNjMuNzkyLjk1Ni4xNjYgMS40NTMuOTkzIDEuNDUzIDEuODEgMCAuNDc1LS4xNjguOTUyLS40OTQgMS4zMDIuMzI2LjM1LjQ5NC44MjcuNDk0IDEuMzAyeiIgZmlsbD0iI0QyMzczNyIvPjwvc3ZnPg==);

$amreview-slider-arrow__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjQzRDNEM0Ii8+PC9zdmc+);
$amreview-slider-arrow__hover__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjMTYxNjE2Ii8+PC9zdmc+);

$amreview-advantages__background: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PGNpcmNsZSBjeD0iMjUiIGN5PSIyNSIgcj0iMjUiIGZpbGw9IiM0M2IwNWMiLz48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yNSAxM3YyNW0xMy0xM0gxMyIvPjwvc3ZnPg==) no-repeat;
$amreview-disadvantages__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTS0xLTFoNTgydjQwMkgtMXoiLz48Y2lyY2xlIGZpbGw9IiNkODVhNGEiIHI9IjI1IiBjeT0iMjUiIGN4PSIyNSIvPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgZD0iTTM4IDI1SDEyIi8+PC9zdmc+) no-repeat;

$amreview-widget-arrow-right__background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNDUxLjg0NiA0NTEuODQ3Ij48cGF0aCBkPSJNMzQ1LjQ0MSAyNDguMjkyTDE1MS4xNTQgNDQyLjU3M2MtMTIuMzU5IDEyLjM2NS0zMi4zOTcgMTIuMzY1LTQ0Ljc1IDAtMTIuMzU0LTEyLjM1NC0xMi4zNTQtMzIuMzkxIDAtNDQuNzQ0TDI3OC4zMTggMjI1LjkyIDEwNi40MDkgNTQuMDE3Yy0xMi4zNTQtMTIuMzU5LTEyLjM1NC0zMi4zOTQgMC00NC43NDggMTIuMzU0LTEyLjM1OSAzMi4zOTEtMTIuMzU5IDQ0Ljc1IDBsMTk0LjI4NyAxOTQuMjg0YzYuMTc3IDYuMTggOS4yNjIgMTQuMjcxIDkuMjYyIDIyLjM2NiAwIDguMDk5LTMuMDkxIDE2LjE5Ni05LjI2NyAyMi4zNzN6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjNmI4ZmFiIiBmaWxsPSIjNmI4ZmFiIi8+PC9zdmc+) no-repeat center;
$amreview-widget-arrow-down__background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgMjg0LjkyOSAyODQuOTI5Ij48cGF0aCBkPSJNMi44NDcgODkuNjQ0bDEzMy4wNTQgMTMzLjA0NWMxLjkwMSAxLjkwMyA0LjA4OCAyLjg1NiA2LjU2MiAyLjg1NnM0LjY2NS0uOTUzIDYuNTY3LTIuODU2TDI4Mi4wNzMgODkuNjQ0YzEuOTA2LTEuOTA2IDIuODU2LTQuMDkzIDIuODU2LTYuNTY4IDAtMi40NzQtLjk1My00LjY2NC0yLjg1Ni02LjU2NmwtMTQuMjcyLTE0LjI3MWMtMS45MDMtMS45MDMtNC4wOTMtMi44NTQtNi41NjctMi44NTRzLTQuNjY0Ljk1MS02LjU2NyAyLjg1NEwxNDIuNDYzIDE3NC40NDEgMzAuMjU1IDYyLjIzMmMtMS45MDItMS45MDMtNC4wOTMtMi44NDgtNi41NjMtMi44NDgtMi40NzggMC00LjY2OC45NTEtNi41NyAyLjg0OEwyLjg0OCA3Ni41MDlDLjk0NiA3OC40MTEuMDAxIDgwLjYwMi4wMDEgODMuMDc1IDAgODUuNTUxLjk0NSA4Ny43NDEgMi44NDcgODkuNjQ0eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCIgZmlsbD0iIzg1ODU4NSIvPjwvc3ZnPg==) no-repeat center;
$amreview-widget-arrow-up__background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgMjg0LjkyOSAyODQuOTI5Ij48cGF0aCBkPSJNMjgyLjA4MiAxOTUuMjg1TDE0OS4wMjggNjIuMjRjLTEuOTAxLTEuOTAzLTQuMDg4LTIuODU2LTYuNTYyLTIuODU2cy00LjY2NS45NTMtNi41NjcgMi44NTZMMi44NTYgMTk1LjI4NUMuOTUgMTk3LjE5MSAwIDE5OS4zNzggMCAyMDEuODUzYzAgMi40NzQuOTUzIDQuNjY0IDIuODU2IDYuNTY2bDE0LjI3MiAxNC4yNzFjMS45MDMgMS45MDMgNC4wOTMgMi44NTQgNi41NjcgMi44NTRzNC42NjQtLjk1MSA2LjU2Ny0yLjg1NGwxMTIuMjA0LTExMi4yMDIgMTEyLjIwOCAxMTIuMjA5YzEuOTAyIDEuOTAzIDQuMDkzIDIuODQ4IDYuNTYzIDIuODQ4IDIuNDc4IDAgNC42NjgtLjk1MSA2LjU3LTIuODQ4bDE0LjI3NC0xNC4yNzdjMS45MDItMS45MDIgMi44NDctNC4wOTMgMi44NDctNi41NjYuMDAxLTIuNDc2LS45NDQtNC42NjYtMi44NDYtNi41Njl6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIiBmaWxsPSIjODU4NTg1Ii8+PC9zdmc+) no-repeat center;
