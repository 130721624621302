$footer__background-color                        : $color-primary;
$footer__newsletter-border                       : $border-width-tetriary $border-style-base $gray-dark;

$footer__links-label-background                  : $color-primary;
$footer__links-label-color                       : $gray-lightest;
$footer__links-dropdown-background               : $color-primary;
$footer__links-dropdown-list-item-border         : $border-width-base $border-style-base $gray-dark;
$footer__links-dropdown-list-border              : $border-width-tetriary $border-style-base $gray-dark;

$footer__links-dropdown-icon-color               : $gray-dark;
$footer__links-dropdown-icon-color-hover         : $gray-lightest;
$footer__links-dropdown-icon-color-open          : $gray-lightest;

$footer__link-color                              : $gray-lightest;
$footer__link-color-hover                        : $color-focus;

$footer__social-list-title-color                 : $gray-lightest;
$footer__social-list-title-font-weight           : $font-weight-medium;

$footer__copywrite-color                         : $gray;
$footer__copywrite-font-size                     : $font-size-small;

$footer__links-dropdown-list-padding\@medium     : $spacer--large 0 0;
$footer__links-dropdown-list-padding\@large      : $footer__links-dropdown-list-padding\@medium;
$footer__links-dropdown-list-padding\@extra-large: $footer__links-dropdown-list-padding\@medium;

$footer__newsletter-padding\@large               : $spacer--medium $spacer--extra-large $spacer--extra-large 0;

$footer__links-dropdown-list-item-width-first-child\@medium: 25%;

@import 'footer';
@import 'bbq-footer';
