@import 'nav-variables';

.dashboard-nav {
    &__mobile {
        @extend %arrow;

        display: block;
        position: relative;
        border: $dashboard-nav__mobile-border;
        border-width: $dashboard-nav__mobile-border-width;
        padding: $dashboard-nav__mobile-padding;
        font-weight: $dashboard-nav__mobile-font-weight;
        cursor: pointer;

        @include mq($screen-l) {
            display: none;
        }

        &--active {
            @extend %arrow-active;
        }
    }

    &__content {
        display: none;

        &--visible {
            display: block;
        }

        @include mq($screen-l) {
            display: block;
        }
    }

    &__item {
        position: relative;
        padding: $dashboard-nav__item-padding;
        transition: $dashboard-nav__item-transition;
    }

    &__item > a,
    &__link {
        @extend %arrow;
        display: block;
        padding: $dashboard-nav__link-padding;
        text-decoration: $dashboard-nav__link-text-decoration;
        font-size: $dashboard-nav__link-font-size;

        &:after {
            transform: rotate(-45deg);
            @include mq($max-screen: $screen-l - 1px) {
                display: none;
            }
        }

        &:hover {
            background-color: $dashboard-nav__link-bg-hover;
            color: $dashboard-nav__link-color-hover;
            font-weight: $dashboard-nav__link-font-weight-hover;
            text-decoration: $dashboard-nav__link-text-decoration-hover;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin: $dashboard-nav__actions-margin;

        @include mq($screen-l) {
            margin: $dashboard-nav__actions-margin\@large;
        }
    }

    &__button {
        width: 100%;

        @include mq($screen-m) {
            width: auto;
            min-width: $dashboard-nav__button-min-width\@medium;
        }

        @include mq($screen-l) {
            width: 100%;
            min-width: auto;
        }
    }

    &__logout-button {
        display: none;

        @include mq($screen-l) {
            display: flex;
        }

        &--mobile {
            display: flex;

            @include mq($screen-l) {
                display: none;
            }
        }
    }

    &__section {
        display: none;

        @include mq($screen-l) {
            display: flex;
            flex-direction: column;
        }
    }

    &__section-title {
        margin: $dashboard-nav__section-title-margin;
        font-family: $dashboard-nav__section-title-font-family;
        font-size: $dashboard-nav__section-title-font-size;
        letter-spacing: $dashboard-nav__section-title-letter-spacing;
    }

    &__section-info {
        margin: $dashboard-nav__section-info-margin;
    }

    // This class is changing by Magento
    .current {
        position: relative;
        background: $dashboard-nav__link-bg-current;

        .dashboard-nav__link {
            font-weight: $dashboard-nav__link-font-weight-current;

            &:hover {
                color: $dashboard-nav__link-color-current-hover;
                text-decoration: $dashboard-nav__link-text-decoration-current-hover;
            }
        }
    }

    // This clases to disabled links module
    .nav.item {
        @extend .list__item;
        @extend .dashboard-nav__item;

        @include mq($screen-l) {
            font-size: $dashboard-nav__item-font-size\@large;
        }

        &:not(.current) > a {
            padding: $dashboard-nav__item-link-padding;

            &:after {
                transform: $dashboard-nav__item-link-transform-after;
                top: $dashboard-nav__item-link-top-after;
            }
        }
    }

    .nav.item.current {
        @extend .current;
    }

    .nav.item.current {
        @extend .dashboard-nav__link;

        padding: $dashboard-nav__link-padding-current;

        & > a {
            padding: 0;
            &:after {
                display: none;
            }
        }
    }

    .nav.item > a {
        @extend .link;
        @extend .dashboard-nav__link;
    }
}
