@import 'contact-us-variables';

.contact-us {
    max-width: $contact-us__max-width;
    margin: $contact-us__margin;

    @include mq($screen-l) {
        max-width: 100%;
    }

    &__heading {
        margin: $contact-us__heading-margin;
        font-family: $contact-us__heading-font-family;
        font-size: $contact-us__heading-font-size;
        font-weight: $contact-us__heading-font-weight;
    }

    &__legend {
        @include visually-hidden();
    }

    &__description {
        margin: $contact-us__description-margin;
        color: $contact-us__description-color;
    }

    &__description p {
        color: $contact-us__description-color;
    }

    &__button {
        margin: $contact-us__button-margin;
    }

    &__tips {
        margin-top: $spacer--semi-medium;
        color: $contact-us__tips-color;
    }

    &__details {
        margin-top: $spacer--extra-large;

        @include mq($screen-l) {
            padding: $contact-us__details-padding\@large;
            margin-top: 0;
        }

        @include mq($screen-xl) {
            padding: $contact-us__details-padding\@xl;
        }
    }

    &__list {
        padding: 0;

        @include mq($screen-l) {
            max-width: $contact-us__list-max-width\@large;
        }
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacer--medium;
    }

    &__day {
        font-weight: $font-weight-bold;

        @include mq($screen-m) {
            font-size: $font-size-large;
        }

        &--special {
            color: $contact-us__day--special-color;
        }
    }

    &__hour {
        color: $color-secondary;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }

        &--special {
            color: $contact-us__hour--special-color;
            font-weight: $font-weight-bold;
        }
    }

    &__phone,
    &__address {
        margin-bottom: $spacer--extra-large;
    }

    &__phone-number {
        display: flex;
        font-size: $contact-us__phone-number-font-size;
        font-weight: $contact-us__phone-number-font-weight;
    }

    &__address-item {
        margin-bottom: 0;
        color: $contact-us__address-item-color;
    }

    // Override webforms styles
    .webforms {
        .fieldset {
            padding: $contact-us__fieldset-padding;
        }
    }
}
