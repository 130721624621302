$button__background                    : $color-secondary;

$button__background-hover--icon-light  : $color-primary;
$button__icon-fill-hover--icon-light   : $gray-lightest;
$button__text-color-hover--icon-light  : $gray-lightest;

$button__before-background--rotate-icon: $color-primary;
$button__icon-fill-hover--rotate-icon  : $gray-lightest;

$button__text-decoration--link         : none;
$button__background-hover--link        : $color-primary;
$button__color-hover--link             : $gray-lightest;

$button__border--icon-border           : 2px solid $color-primary;
$button__background--icon-border       : $gray-lightest;
$button__background-hover--icon-border : $color-primary;

@import 'button';
@import 'bbq-button';
