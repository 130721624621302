.newsletter {
    &__checkbox {
        .checkbox__field {
            &:checked {
                & ~ .checkbox__label {
                    color: $gray-lightest;
                }
            }
        }

        .checkbox__label {
            &:after {
                background-color: $white;
            }
        }

        .checkbox__icon {
            fill: $gray-darkest;
        }
    }
}
