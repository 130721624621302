$contact-bar__height                             : 40px !default;
$contact-bar__margin                             : 0 !default;
$contact-bar__padding                            : 0 !default;
$contact-bar__background                         : $gray-light !default;
$contact-bar__display-from                       : $screen-l !default;

$contact-bar__container-width                    : calc(100% - #{$spacer--medium}) !default;
$contact-bar__container-direction                : row-reverse !default;
$contact-bar__container-justify                  : space-between !default;

$contact-bar__item-padding                       : 0 !default;
$contact-bar__item-margin                        : 0 $spacer--small !default;

$contact-bar__link-padding                       : $spacer !default;
$contact-bar__link-font-size                     : $font-size-small !default;
$contact-bar__link-font-weight                   : $font-weight-normal !default;
$contact-bar__link-line-height                   : $font-line-height !default;
$contact-bar__link-bg                            : $gray-light !default;

$contact-bar__link-before-bg                     : $gray !default;
$contact-bar__link-min-height                    : 40px !default;
$contact-bar__link-text-transform                : uppercase !default;
$contact-bar__link-color                         : $color-primary !default;
$contact-bar__link-color--hover                  : $color-primary !default;
$contact-bar__link-text-decoration               : none !default;
$contact-bar__link-align-items                   : center !default;

$contact-bar__link-hover-text-decoration         : none !default;
$contact-bar__link-hover-background              : transparent !default;
$contact-bar__link-hover-background-hover        : $gray !default;

$contact-bar__link-font-size--icons              : $font-size-small !default;
$contact-bar__link-font-weight--icons            : $font-weight-normal !default;
$contact-bar__link-text-transform--icons         : uppercase !default;

$contact-bar__icon-margin                        : 0 $spacer 0 0 !default;
$contact-bar__icon-fill                          : $color-primary !default;
$contact-bar__icon-fill-hover                    : $color-primary !default;

// vertical
$contact-bar__container-width--vertical          : 100% !default;
$contact-bar__bg--vertical                       : $white !default;

$contact-bar__list-align-items--vertical         : left !default;
$contact-bar__list-icons-align-items--vertical   : left !default;
$contact-bar__list-icons-border--vertical        : $border-base !default;
$contact-bar__list-icons-border-width--vertical  : 1px 0 0 0 !default;
$contact-bar__list-icons-margin--vertical        : $spacer--semi-large 0 !default;
$contact-bar__list-icons-link-font-size--vertical: $font-size-small !default;

$contact-bar__item-margin--vertical              : 0 !default;

$contact-bar__icon-fill--vertical                : $color-primary !default;

$contact-bar__link-before-display--vertical      : none !default;
$contact-bar__link-bg--vertical                  : transparent !default;
$contact-bar__link-color--vertical               : $color-primary !default;
$contact-bar__link-color-hover--vertical         : $color-primary !default;
$contact-bar__link-font-size--vertical           : $font-size-base !default;
$contact-bar__link-padding--vertical             : $spacer--medium 0 !default;
$contact-bar__link-text-transform--vertical      : capitalize !default;

$contact-bar__transition                         : $transition-base !default;
