@import 'forgot-password-variables';

.forgot-password {
    max-width: $forgot-password__max-width;
    margin: 0 auto $forgot-password__margin-bottom;

    &__email,
    &__captcha-field {
        margin-bottom: $forgot-password__field-margin-bottom;
    }

    &__captcha-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: $forgot-password__captcha-wrapper-margin-bottom;
    }

    &__image {
        padding-right: $forgot-password__image-padding-right;
        width: $forgot-password__image-width;
        max-width: $forgot-password__image-max-width;
    }

    &__info {
        margin-bottom: $forgot-password__info-margin-bottom;
        color: $forgot-password__info-color;
        font-size: $forgot-password__info-font-size;

        @include mq($screen-m) {
            text-align: center;
        }
    }

    &__actions {
        padding: $forgot-password__actions-padding;
        text-align: center;
    }

    &__heading {
        margin:  $forgot-password__heading-margin;
    }

    &__captcha {
        padding: $forgot-password__captcha-bottom-padding;
    }
}
