$more-info__trigger-bg              : transparent !default;
$more-info__trigger-color           : $color-primary !default;
$more-info__trigger-font-size       : $font-size-medium !default;
$more-info__trigger-font-weight     : $font-weight-normal !default;
$more-info__trigger-text-decoration : underline !default;
$more-info__trigger-text-transform  : none !default;
$more-info__trigger-text-margin     : 0 !default;
$more-info__trigger-padding         : 0 !default;

$more-info__trigger-icon-fill       : $color-secondary !default;
$more-info__trigger-icon-margin     : 0 $spacer--small !default;

$more-info__content-color           : $white !default;
$more-info__content-wrapper-padding : $spacer--medium !default;
$more-info__content-wrapper-bg      : $color-primary !default;
$more-info__content-wrapper-z-index : $z-index-normal !default;

$more-info__close-top               : -$spacer !default;
$more-info__close-right             : -$spacer !default;
$more-info__close-background        : transparent !default;
$more-info__close-icon-fill         : $white !default;

$more-info__heading-color           : $white !default;
$more-info__heading-font-family     : $font-family-base !default;
$more-info__heading-font-weight     : $font-weight-bold !default;
$more-info__heading-text-transform  : none !default;
