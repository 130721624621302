.home {
    &:not(.breadcrumbs__item) {
        display: flex;
        flex-direction: column;
    }

    &__banner {
        margin: 0 0 $spacer--medium;

        @include mq($screen-m) {
            margin: 0 0 $spacer--large;
        }

        @include mq($screen-l) {
            margin: 0 0 $spacer--medium;
        }
    }
}
