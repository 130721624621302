$auth__link-width               : 100% !default;
$auth__link-width\@medium       : 440px !default;
$auth__link-height              : 72px !default;
$auth__link-border              : $border-base !default;
$auth__link-border-width        : $border-width-base 0 !default;
$auth__link-color               : $color-secondary !default;
$auth__link-color--active       : $color-primary !default;
$auth__link-background--active  : $gray-light !default;
$auth__link-font-size           : $font-size-large !default;
$auth__link-font-weight         : $font-weight-normal !default;
$auth__link-font-weight--active : $font-weight-bold !default;
$auth__link-text-decoration     : none !default;
$auth__link-text-transform      : uppercase !default;

$auth__icon-transform--active   : rotate(180deg) !default;

$auth__heading-margin           : $spacer--extra-large 0 $spacer--semi-large !default;
$auth__heading-margin\@medium   : 120px 0 72px !default;

$auth__container-max-width      : 880px !default;
$auth__container-margin         : 0 auto !default;
