%error {
    color: $color-error;
    font-weight: $font-weight-normal;
}

//sass-lint:disable no-important
.product-view {
    .yotpo {
        div,
        span,
        p,
        a,
        img,
        i,
        strong,
        sup,
        ul,
        li,
        form,
        label,
        .yotpo-default-button,
        input[type="button"].yotpo-default-button {
            font-family: $font-family-base;
            font-size: $font-size-base;
        }

        a {
            color: $color-primary !important;
            background: transparent !important;

            &:hover,
            &:focus {
                color: $color-secondary !important;
                background: transparent !important;
            }
        }

        .yotpo-default-button,
        input[type="button"].yotpo-default-button {
            min-height: 48px;
            background: $color-secondary;
            font-weight: $font-weight-bold !important;
            letter-spacing: 1px;
            transition: $transition-base;

            &:hover,
            &:focus {
                background: $color-primary !important;
            }
        }

        .yotpo-bottomline {
            .yotpo-icon-star,
            .yotpo-icon-half-star,
            .yotpo-icon-empty-star {
                color: $yellow !important;
            }
        }

        &.yotpo-main-widget {
            .yotpo-icon-btn {
                display: inline-flex;
                align-items: center;
            }

            .yotpo-thank-you {
                .yotpo-icon-btn {
                    .yotpo-icon-button-text {
                        display: none;
                    }
                }
            }
        }

        .yotpo-review {
            .yotpo-header {
                .yotpo-review-stars {
                    .yotpo-icon {
                        color: $yellow;
                    }
                }
            }
        }

        .write-question-button:not(.write-first-question-button),
        .write-button:not(.write-first-review-button) {
            min-height: auto;

            .yotpo-icon {
                margin-right: 4px;
            }
        }

        .write-review {
            .connect-wrapper {
                padding-left: 200px;

                .form-element {
                    .form-input-error  {
                        @extend %error;
                    }
                }
            }

            .yotpo-header {
                .y-label {
                    .form-input-error {
                        @extend %error;
                    }
                }

                .yotpo-header-title {
                    @extend .heading;
                    @extend .heading--second-level;
                }

                .yotpo-icon {
                    color: $yellow;
                }
            }

            .submit-button {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;

                .form-input-error {
                    @extend %error;

                    margin: $spacer--medium 0;
                }
            }

            .yotpo-submit {
                max-width: 148px;
            }

            .socialize-wrapper {
                .yotpo-or {
                    margin: 68px 8px 0;
                    width: auto;
                }
            }
        }

        .form-group .form-element .form-input-error {
            @extend %error;
        }

        .yotpo-regular-box {
            margin: 0 $spacer $spacer--medium;
            border: none;
        }

        input,
        textarea {
            @extend .input__field;
        }

        textarea {
            @extend .input__field--textarea;
        }
    }

    .yotpoBottomLine {
        a {
            margin-right: $spacer--small;
            display: flex;
            align-items: center;
        }

        .yotpo-stars {
            margin-right: $spacer;
        }

        .yotpo-icon-double-bubble {
            margin-right: 4px;
        }
    }

    .bottomLine,
    .QABottomLine {
        margin-bottom: $spacer;
    }

    .y-label {
        &.yotpo-header-title {
            @extend .heading;
            @extend .heading--second-level;
        }
    }
}

.product-list-item,
.product-grid-item {
    .yotpo {
        .text-m {
            display: none;
        }
    }
}

//sass-lint:enable no-important
