$mega-menu__link-padding\@xl: 0 $spacer--semi-medium;

.mega-menu {
    &__link {
        letter-spacing: 1px;
        text-align: center;

        @include mq($screen-xl) {
            padding: $mega-menu__link-padding\@xl;
        }
    }

    &__item {
        &--special {
            .mega-menu__link {
                color: $color-secondary;
            }
        }

        &:hover,
        &:active,
        .focus-visible {
            .mega-menu__inner-list--level1 {
                display: none;
            }
        }

        & > .mega-menu__inner-list--level1:focus {
            display: none;
        }

        &:focus-within {
            .mega-menu__inner-list--level1 {
                display: none;
            }
        }

        & > .mega-menu__link:focus + .mega-menu__inner-list--level1 {
            display: none;
        }

        &--active {
            .mega-menu__inner-list--level1 {
                display: flex;
            }

            .mega-menu__link {
                color: $mega-menu__link-color--hover;
                text-decoration: $mega-menu__link-text-decoration--hover;
                background-color: $mega-menu__link-background-color--hover;

                &:focus {
                    + .mega-menu__inner-list--level1 {
                        display: flex;
                    }
                }
            }

            &:hover,
            &:active,
            &:focus-within,
            .focus-visible {
                .mega-menu__inner-list--level1 {
                    display: flex;
                }
            }
        }
    }
}
