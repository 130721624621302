.checkbox {
    &--no-label {
        width: $checkbox__icon-size;
        height: $checkbox__icon-size;

        .checkbox__label {
            position: absolute;
            top: 0;
            left: 0;
            width: $checkbox__icon-size;
            height: $checkbox__icon-size;
        }
    }
}
