$store-locator__button-bg--light: $white;
$store-locator__button-color--dark: $color-primary;
$store-locator__border--dark: $color-primary;
$store-locator__outline: $white;

.store-locator {
    &__button-search {
        top: 0;

        .ampickup-map-popup & {
            top: 0;
        }
    }

    &__filter-actions {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        padding: $spacer $spacer--medium;
    }

    &__stores {
        @include mq($screen-m) {
            flex-direction: column;
        }

        @include mq($screen-xl) {
            flex-direction: row;
        }
    }

    .amlocator-button {
        &.button.-pickup {
            color: $store-locator__button-color;
            background-color: $store-locator__button-bg;
            border: $store-locator__border;
            font-weight: $store-locator__button-font-weight;
            min-height: $store-locator__button-min-size;
            padding: $store-locator__button-padding;

            &:hover,
            &:focus,
            &.focus-visible {
                background-color: $store-locator__button-bg--active;
            }
        }
    }

    // adjust store-locator styles
    .amlocator-search-container {
        background-color: transparent;
        border-bottom: $border-base;
        padding: 0 0 $spacer--medium;
        margin-bottom: $spacer--medium;

        .amlocator-title {
            margin-bottom: $spacer--medium;

            .ampickup-map-popup & {
                display: inline-block;
            }

            .amlocator-link {
                word-break: keep-all;
            }
        }

        .amlocator-button {
            &.button {
                color: $store-locator__button-color;
                background-color: $store-locator__button-bg;
                border: $store-locator__border;
                font-weight: $store-locator__button-font-weight;
                min-height: $store-locator__button-min-size;
                padding: $store-locator__button-padding;

                &:hover,
                &:focus,
                &.focus-visible {
                    background-color: $store-locator__button-bg--active;
                }

                &.-nearby {
                    color: $store-locator__button-color;
                    background-color: $store-locator__button-bg;

                    &:hover,
                    &:focus,
                    &.focus-visible {
                        background-color: $store-locator__button-bg--active;
                    }

                    &:disabled,
                    &[disabled] {
                        @extend .button:disabled;
                    }
                }
            }
        }
    }

    .amlocator-filters-container {
        background-color: transparent;
    }

    .amlocator-store-desc {
        background-color: $gray-lighter;
        border: none !important;
        &.-active {
            background-color: $color-primary;

            .amlocator-button {
                &.button.-pickup {
                    background-color: $store-locator__button-bg--light;
                    color: $store-locator__button-color--dark;
                    border-color: $store-locator__border--dark;

                    &:hover,
                    &:focus,
                    &.focus-visible {
                        color: $store-locator__button-color;
                        background: $store-locator__button-bg;
                        outline: 1px solid $store-locator__outline;
                    }
                }
            }

            .amlocator-today {
                color: $color-primary;
                background-color: $color-info;

                @include mq($screen-m, $screen-xl) {
                    flex-wrap: wrap;
                }

                .amlocator-time {
                    @include mq($screen-m, $screen-xl) {
                        width: 100%;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .amlocator-schedule-table {
        margin-top: $spacer--medium;
    }

    // maps popup
    .amlocator-description {
        margin-top: $spacer;
    }

    .amlocator-info-popup {
        font-weight: $font-weight-base;
        font-size: $font-size-base;
        line-height: $font-line-height;
    }

    .amlocator-description {
        p {
            font-size: $font-size-base;
        }
    }

    .input__field {
        font-size: $font-size-base;
    }

    .amlocator-current-location {
        .amlocator-text {
            height: $store-locator__action-height;

            &:hover,
            &:focus,
            &.focus-visible {
                border: $store-locator__input-border--active;
            }
        }

        .amlocator-wrapper {
            margin-top: 0;
        }
    }

    // overwrite google maps popup styles
    .amlocator-map-container .amlocator-map button:not(.-pickup) {
        // sass-lint:disable-block no-important
        width: 40px !important;
        height: 40px !important;
        right: $spacer--extra-small !important;

        & > img {
            width: 24px !important;
            height: 24px !important;
        }
    }
}

.amlocator-location-container {
    .amlocator-modes {
        border: none;
    }

    .amlocator-route-creator {
        background-color: $store-locator__route-creator-bg;

        .amlocator-mode,
        .amlocator-swap {
            display: unset;
            @include visually-hidden;

            &+ .amlocator-travel-mode {
                opacity: $store-locator__route-creator-icon-opacity;
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(100%);
            }

            &:focus-visible + .amlocator-travel-mode {
                opacity: 1;
                @include focus;
            }
        }

        .amlocator-mode:checked + .amlocator-travel-mode,
        .amlocator-mode:focus + .amlocator-travel-mode,
        .amlocator-mode + .amlocator-travel-mode:hover {
            background-color: $store-locator__route-creator-label-bg;
        }

        .amlocator-route-icon .amlocator-icon.-dot {
            opacity: $store-locator__route-creator-icon-opacity;
            background-color: $store-locator__route-creator-icon-bg;
        }
    }

    .amlocator-block {
        margin-bottom: $spacer--medium;

        &__title {
            font-weight: $font-weight-bold;
        }

        &__item {
            display: block;
            font-size: $font-size-base;

            @include mq($screen-m) {
                font-size: $font-size-medium;
            }
        }
    }

    .amlocator-link {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .amlocator-features {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: $font-size-base;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }

        &__item {
            margin-bottom: $spacer--small;
            display: flex;
            align-items: center;

            .icon {
                margin-right: $spacer;
            }
        }
    }

    .amlocator-review-link {
        display: flex;
        align-items: center;
        font-size: $font-size-base;
        font-weight: 700;

        @include mq($screen-m) {
            font-size: $font-size-medium;
        }


        &:hover {
            text-decoration: none;

            span {
                text-decoration: none;
            }
        }

        .icon {
            width: 48px;
            height: 48px;
            margin-right: $spacer--medium;
        }

        span {
            text-decoration: underline;
        }
    }
}

// Override amlocator css
.amlocator-location-view {
    .amlocator-location-main {
        .amlocator-location-container {
            & > .amlocator-column {
                &.-left {
                    background: none;
                }
            }

            .amlocator-schedule-container {
                .amlocator-today {
                    font-size: $font-size-base;
                    margin-bottom: $spacer;

                    @include mq($screen-m) {
                        font-size: $font-size-medium;
                    }

                    &:before {
                        top: 8px;
                    }
                }

            }
        }

        .amlocator-description {
            margin-top: $spacer--extra-large;
        }
    }
}

// New styles for heading
.amlocator-index-index,
.amlocator-location-view {
    .page-main {
        & > .heading {
            display: inline-block;
            position: relative;

            &:after {
                content: '';
                background-color: $bloody-red;
                position: absolute;
                bottom: calc(-0.5em - 4px);
                left: 0;
                width: 100%;
                height: 4px;
            }
        }
        .amlocator-search-container,
        .amlocator-column.-left {
            padding-top: $spacer--medium;
            border-top: 4px solid $gray-lighter;
        }
    }
}
