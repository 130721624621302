.store-selector {
    &__content {
        p {
            margin-bottom: $spacer--medium;
        }
    }

    &__toolbar-button {
        margin-bottom: $spacer--medium;

        @include mq($screen-m) {
            margin-bottom: 0;
        }
    }
}
