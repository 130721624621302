@font-face {
    font-family: 'Roboto';
    src:
    url('../fonts/roboto-regular.woff2') format('woff2'),
    url('../fonts/roboto-regular.woff') format('woff'),
    url('../fonts/roboto-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src:
    url('../fonts/roboto-bold.woff2') format('woff2'),
    url('../fonts/roboto-bold.woff') format('woff'),
    url('../fonts/roboto-bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src:
    url('../fonts/roboto-black.woff2') format('woff2'),
    url('../fonts/roboto-black.woff') format('woff'),
    url('../fonts/roboto-black.ttf') format('ttf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src:
    url('../fonts/RobotoSlab-Regular.woff2') format('woff2'),
    url('../fonts/RobotoSlab-Regular.woff') format('woff'),
    url('../fonts/RobotoSlab-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src:
    url('../fonts/RobotoSlab-SemiBold.woff2') format('woff2'),
    url('../fonts/RobotoSlab-SemiBold.woff') format('woff'),
    url('../fonts/RobotoSlab-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
