.add-to-cart {
    &--with-compare-bar {
        // compare bar height is 49px
        min-height: calc(2 * #{$spacer--extra-large} + 49px + #{$spacer});

        @include mq($screen-m) {
            min-height: calc(2 * #{$spacer--extra-large} + 49px);
        }
    }
}
