@mixin add-to-button($iconDisplay) {
    @if ($iconDisplay == block) {
        padding: $button__padding--icon;
        &:after {
            content: $button__after-content-icon--add-to;
        }
        .button__icon {
            display: $iconDisplay;
        }
        .button__text {
            display: none;
        }
    }
    @else {
        padding: $button__padding;
        &:after {
            content: $button__after-content-text--add-to;
        }
        .button__icon {
            display: $iconDisplay;
        }
        .button__text {
            display: block;
        }
    }
}
