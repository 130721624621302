// Cart grid
$cart-grid__header-border-bottom          : $border-base !default;
$cart-grid__header-label-padding          : $spacer--medium 0 !default;
$cart-grid__footer-justify-content\@medium: space-between !default;
$cart-grid__footer-flex-direction\@medium : row-reverse !default;

// Cart discount
$cart-discount__margin                    : $spacer--medium 0 !default;
$cart-discount__padding                   : 0 !default;
$cart-discount__button-icon-transition    : $transition-base !default;
$cart-discount__button-padding            : $spacer--medium 0 !default;
$cart-discount__button-border             : $border-base !default;
$cart-discount__button-border-width       : 0 0 1px 0 !default;
$cart-discount__button-text-color         : $color-primary !default;
$cart-discount__button-text-margin        : 0 $spacer--medium 0 0 !default;
$cart-discount__button-text-font-size     : $font-size-large !default;
$cart-discount__button-text-font-weight   : $font-weight-base !default;
$cart-discount__button-icon-transform     : rotate(180deg) !default;
$cart-discount__button-min-width          : 150px !default;
$cart-discount__button-background         : none !default;
$cart-discount__button-background--hover  : none !default;
$cart-discount__button-font-family        : $font-family-base !default;
$cart-discount__controls-margin-bottom    : $spacer--medium !default;
$cart-discount__controls-padding          : $spacer--semi-medium 0 !default;
$cart-discount__input-margin              : 0 !default;
$cart-discount__button-text-transform     : uppercase !default;
