// -----------------------------------------------------------
// Please keep balance between Magento_UI and Alpaca z-indexes
// -----------------------------------------------------------
// COMPONENT - z-index OVERLAY / CONTENT (method)
// -----------------------------------------------------------
// Alpaca Modal - 99 / 99 (Styles)
// Magento_UI Modal - 99 / 100 (Inlined via JS)
// Magento_UI Confirmation Modal - 100 / 101 (Inlined via JS)
// -----------------------------------------------------------
$modal__z-index                              : $z-index-highest !default;
$modal-ui-base__z-index                      : $z-index-highest + 1 !default;
$modal-ui-confirm__z-index                   : $z-index-highest + 2 !default;
// -----------------------------------------------------------

$modal__padding                              : 0 !default;
$modal__background-color                     : rgba(0, 0, 0, 0.7) !default;
$modal__justify-content                      : center !default;
$modal__align-items                          : center !default;
$modal__border                               : 0 !default;

//modal container
$modal__container-width                      : calc(100% - (2 * #{$spacer--medium})) !default;
$modal__container-max-width                  : 900px !default;
$modal__container-padding                    : $spacer--semi-large $spacer--medium !default;
$modal__container-padding\@medium            : $spacer--semi-large !default;
$modal__container-background-color           : $white !default;
$modal__container-border                     : none !default;
$modal__container-box-shadow                 : $shadow !default;
$modal__container-animation                  : animatetop 0.4s !default;

//modal close
$modal__close-button-top                     : $spacer--medium !default;
$modal__close-button-right                   : $spacer--medium !default;

//modal top
$modal__top-padding                          : 0 0 $spacer--semi-medium 0 !default;
$modal__top-margin                           : 0 !default;
$modal__top-border                           : $border-base !default;
$modal__top-border-width                     : 0 0 $border-width-base 0 !default;

//modal middle
$modal__middle-padding                       : $spacer--semi-large !default;
$modal__middle-margin                        : 0 !default;
$modal__middle-border                        : 0 !default;
$modal__middle-border-width                  : 0 !default;

//modal bottom
$modal__bottom-padding                       : $spacer--semi-large 0 0 0 !default;
$modal__bottom-margin                        : 0 !default;
$modal__bottom-border                        : $border-base !default;
$modal__bottom-border-width                  : $border-width-base 0 0 0 !default;

//modal heading
$modal__heading-font-size                    : $font-size-large !default;
$modal__heading-font-weight                  : $font-weight-base !default;
$modal__heading-margin                       : 0 !default;
$modal__heading-height                       : 48px !default;
$modal__heading-padding-right                : $spacer--extra-large !default;
$modal__heading-padding-left                 : 0 !default;

//modal subheading
$modal__subheading-font-size                 : $font-size-base !default;
$modal__subheading-font-weight               : $font-weight-base !default;
$modal__subheading-margin                    : 0 !default;
$modal__subheading-height                    : 24px !default;
$modal__subheading-padding-right             : 0 !default;
$modal__subheading-padding-left              : 0 !default;

//modal bottom-actions
$modal__bottom-actions-padding               : 0 !default;
$modal__bottom-actions-margin                : 0 auto !default;
$modal__bottom-actions-justify-content       : space-between !default;
$modal__bottom-actions-max-width             : 320px !default;

//modal bottom-action
$modal__bottom-action-padding                : 0 !default;
$modal__bottom-action-margin                 : 0 0 $spacer--medium 0 !default;
$modal__bottom-action-width                  : calc(50% - #{$spacer}) !default;

$modal__bottom-action-button-width           : 100% !default;

//modal--secondary
$modal__justify-content--secondary           : center !default;
$modal__align-items--secondary               : flex-end !default;
$modal__container-animation--secondary       : animateright 0.4s !default;
$modal__container-height--secondary          : 100% !default;
$modal__container-width--secondary           : 100% !default;
$modal__container-max-width--secondary       : 640px !default;
$modal__container-max-width--secondary\@large: 768px !default;
$modal__content-height--secondary            : 100% !default;
$modal__container-padding--secondary\@medium : $spacer--extra-large !default;
$modal__container-padding--secondary\@large  : $spacer--extra-large 112px !default;

//modal--tertiary
$modal__justify-content--tertiary            : center !default;
$modal__align-items--tertiary                : flex-start !default;
$modal__container-animation--tertiary        : animateleft 0.4s !default;

$modal__container-height--tertiary           : 100vh !default;
$modal__container-width--tertiary            : 100% !default;
$modal__container-max-width--tertiary        : 100% !default;
$modal__container-box-shadow--tertiary       : none !default;
