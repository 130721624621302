$mega-menu__background-color                   : $white;
$mega-menu__border                             : $border-secondary;
$mega-menu__border-width                       : 0 0 $border-width-secondary 0;

$mega-menu__link-padding                       : 0 22px;
$mega-menu__link-color                         : $color-primary;
$mega-menu__link-font-weight                   : $font-weight-bold;
$mega-menu__link-background-color--hover       : $color-primary;
$mega-menu__link-color--hover                  : $gray-lightest;

$mega-menu__inner-link-color--children         : $gray-dark;
$mega-menu__inner-link-padding--children       : 0 $spacer--medium;
$mega-menu__inner-link-text-transform          : uppercase;
$mega-menu__inner-link-text-transform--children: capitalize;

@import 'mega-menu';
@import 'bbq-mega-menu';
