// Cart Summary
$order-summary__padding                      : $spacer--semi-medium !default;
$order-summary__background                   : $gray-lighter !default;
$order-summary__margin                       : 0 0 $spacer--extra-large !default;

// Title
$order-summary__title-margin                 : 0 0 $spacer--medium !default;
$order-summary__title-font-family            : $font-family-base !default;
$order-summary__title-font-size              : $font-size-large !default;
$order-summary__title-font-weight            : $font-weight-bold !default;
$order-summary__title-text-transform         : none !default;
$order-summary__title-total-margin           : 0 !default;
$order-summary__title-total-font-family      : $font-family-base !default;
$order-summary__title-total-font-size        : $font-size-large !default;
$order-summary__title-total-font-weight      : $font-weight-bold !default;
$order-summary__title-total-text-transform   : none !default;

// Amount
$order-summary__amount-margin                : 0 !default;
$order-summary__amount-font-family           : $font-family-base !default;
$order-summary__amount-font-size             : $font-size-large !default;
$order-summary__amount-font-weight           : $font-weight-bold !default;
$order-summary__amount-text-transform        : none !default;

// Subtitle
$order-summary__subtitle-margin              : 0 0 $spacer--medium !default;
$order-summary__subtitle-font-family         : $font-family-base !default;
$order-summary__subtitle-font-size           : $font-size-large !default;
$order-summary__subtitle-font-weight         : $font-weight-base !default;
$order-summary__subtitle-text-transform      : none !default;

// List
$order-summary__list-margin                  : 0 !default;
$order-summary__list-padding                 : $spacer--medium 0 !default;
$order-summary__list-border                  : $border-base !default;
$order-summary__list-border-width            : 1px 0 0 0 !default;
$order-summary__list-style                   : none !default;
$order-summary__list-item-min-height         : 48px !default;
$order-summary__label-font-size              : $font-size-medium !default;

// Link
$order-summary__link-padding                 : 0 !default;
$order-summary__link-font-weight             : $font-weight-base !default;

// Divider
$order-summary__divider-margin               : $spacer--medium 0 !default;
$order-summary__divider-border               : $border-base !default;
$order-summary__divider-border-width         : 1px 0 0 !default;

// Tab
$order-summary__tab-title-padding            : $spacer--small 0 !default;
$order-summary__tab-title-border-top         : $border-base !default;
$order-summary__tab-title-border-bottom      : $border-base !default;
$order-summary__tab-text-font-weight         : $font-weight-medium !default;
$order-summary__tab-icon-width               : 14px !default;
$order-summary__tab-icon-height              : 14px !default;
$order-summary__tab-icon-trasition           : $transition-base !default;

// Products
$order-summary__products-display             : none !default;
$order-summary__product-item-padding         : $spacer--semi-medium 0 !default;
$order-summary__product-item-border-bottom   : $border-base !default;
$order-summary__product-image-height         : auto !default;
$order-summary__product-image-width          : 64px !default;
$order-summary__product-image-max-width      : 64px !default;
$order-summary__product-image-margin-right   : $spacer !default;
$order-summary__product-info-width           : 100% !default;
$order-summary__product-details-padding-right: $spacer !default;
$order-summary__product-name-font-size       : $font-size-base !default;
$order-summary__product-name-font-weight     : $font-weight-base !default;
$order-summary__product-qty-color            : $gray-dark !default;
$order-summary__product-price-font-size      : $font-size-base !default;
$order-summary__product-wrapper-width        : 100% !default;
