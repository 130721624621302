$datepicker-white: #f9f9f9;
$datepicker-gray: #959595;

$datepicker__today-background-color              : $datepicker-white;
$datepicker__today-background-color--active      : $datepicker-white;
$datepicker__today-color                         : $datepicker-gray;
$datepicker__today-color--active                 : $datepicker-gray;

$datepicker__ui-state-background-color--active   : $bloody-red;
$datepicker__current-day-background-color        : $bloody-red;
$datepicker__current-day-background-color--active: $bloody-red;
$datepicker__current-day-border                  : 1px solid $bloody-red;

@import 'datepicker';
@import 'bbq-datepicker';
