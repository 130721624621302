//
//  Amasty Popup Component Styles
//  ______________________________________________

//
//  Variables
//  ----------------------------------------------

$amrev-popup__background: #fafafa;

//
//  Common
//  ----------------------------------------------

    .amreview-popup.modal-popup {
        & {
            transition: 0.3s all ease-in;
        }

        .modal-header {
            background: $amrev-popup__background;
        }

        .modal-content {
            padding: 0;
        }

        .block-content {
            background: $amrev-popup__background;
            padding: $amrev__indent $amrev__indent__xl $amrev__indent__xl * 2;
        }

        .modal-inner-wrap {
            max-width: 640px;
        }
    }


//
//  Tablet
//  ----------------------------------------------

@media screen and (min-width: 768px) {
    .amreview-popup.modal-popup .block-content {
        padding: $amrev__indent $amrev__indent__xl * 2 $amrev__indent__xl * 2;
    }
}
