@import 'order-summary-variables';

.order-summary {
    padding: $order-summary__padding;
    background-color: $order-summary__background;
    margin: $order-summary__margin;

    &__title {
        margin: $order-summary__title-margin;
        font-family: $order-summary__title-font-family;
        font-size: $order-summary__title-font-size;
        font-weight: $order-summary__title-font-weight;
        text-transform: $order-summary__title-text-transform;
    }

    &__title-total {
        margin: $order-summary__title-total-margin;
        font-family: $order-summary__title-total-font-family;
        font-size: $order-summary__title-total-font-size;
        font-weight: $order-summary__title-total-font-weight;
        text-transform: $order-summary__title-total-text-transform;
    }

    &__amount {
        margin: $order-summary__amount-margin;
        font-family: $order-summary__amount-font-family;
        font-size: $order-summary__amount-font-size;
        font-weight: $order-summary__amount-font-weight;
        text-transform: $order-summary__amount-text-transform;
    }

    &__subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $order-summary__subtitle-margin;
        font-family: $order-summary__subtitle-font-family;
        font-size: $order-summary__subtitle-font-size;
        font-weight: $order-summary__subtitle-font-weight;
        text-transform: $order-summary__subtitle-text-transform;
        cursor: pointer;
    }

    &__list {
        margin: $order-summary__list-margin;
        padding: $order-summary__list-padding;
        border: $order-summary__list-border;
        border-width: $order-summary__list-border-width;
        list-style: $order-summary__list-style;
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: $order-summary__list-item-min-height;

        &--secondary {
            justify-content: flex-start;
        }
    }

    &__label,
    &__value {
        font-size: $order-summary__label-font-size;
    }

    &__link {
        padding: $order-summary__link-padding;
        font-weight: $order-summary__link-font-weight;
    }

    &__divider {
        margin: $order-summary__divider-margin;
        border: $order-summary__divider-border;
        border-width: $order-summary__divider-border-width;
    }

    &__list-column {
        display: flex;
        flex-direction: column;
    }

    .active {
        .shipping-estimation__title-icon {
            transform: rotate(180deg);
        }
    }

    &__tab {
        &.active {
            .order-summary__tab-icon {
                transform: rotate(180deg);
            }
        }
    }

    &__tab-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $order-summary__tab-title-padding;
        border-top: $order-summary__tab-title-border-top;
        border-bottom: $order-summary__tab-title-border-bottom;
        cursor: pointer;
    }

    &__tab-text {
        font-weight: $order-summary__tab-text-font-weight;
    }

    &__tab-icon {
        width: $order-summary__tab-icon-width;
        height: $order-summary__tab-icon-height;
        transition: $order-summary__tab-icon-trasition;
    }

    &__products {
        display: $order-summary__products-display;
    }

    &__product-item {
        display: flex;
        padding: $order-summary__product-item-padding;
        border-bottom: $order-summary__product-item-border-bottom;
    }

    &__product-image {
        height: $order-summary__product-image-height;
        width: $order-summary__product-image-width;
        max-width: $order-summary__product-image-max-width;
        margin-right: $order-summary__product-image-margin-right;
    }

    &__product-info {
        display: flex;
        justify-content: space-between;
        width: $order-summary__product-info-width;
    }

    &__product-wrapper {
        width: $order-summary__product-wrapper-width;
    }

    &__product-details {
        padding-right: $order-summary__product-details-padding-right;
    }

    &__product-name {
        font-size: $order-summary__product-name-font-size;
        font-weight: $order-summary__product-name-font-weight;
    }

    &__product-qty {
        color: $order-summary__product-qty-color;
    }

    &__product-price {
        font-size: $order-summary__product-price-font-size;
    }
}
