//
//  Comment Block Styles
//  ____________________________________________

//
//  Variables
//  --------------------------------------------

$amrev-comments__active__color: #026bb3;
$amrev-comments-btn__color: #1e79c2;
$amrev-comments-btn__background: #dff1ff;
$amrev-comment__title__border-color: #c8c8c8;
$amrev-comment__form__required-color: #f00f;

//
//  Icons
//  --------------------------------------------

$amrev-arrow-reply__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYuMjAzOSAzLjA3Njg4SDIuMTk4NTdMMy4wMzEyOCAzLjg3MjU3QzMuMjgyNzcgNC4xMTI4OCAzLjI4Mjc3IDQuNTAyNDMgMy4wMzEyOCA0Ljc0Mjc0QzIuNzgxNzIgNC45ODE1MSAyLjM3MzEgNC45ODQ1OSAyLjEyMDY1IDQuNzQyNzRMMC4xODg2MTUgMi44OTY1N0MtMC4wNjI4NzE5IDIuNjU2MjYgLTAuMDYyODcxOSAyLjI2NjcxIDAuMTg4NjE1IDIuMDI2NEwyLjEyMDY1IDAuMTgwMjMyQzIuMzcxODEgLTAuMDYwMDc3NSAyLjc4MDEyIC0wLjA2MDA3NzUgMy4wMzEyOCAwLjE4MDIzMkMzLjI4Mjc3IDAuNDIwNTQzIDMuMjgyNzcgMC44MTAwODUgMy4wMzEyOCAxLjA1MDRMMi4xOTg1NyAxLjg0NjA5SDYuMjAzOUM5LjM5OTgxIDEuODQ2MDkgMTIgNC4zMzA3MyAxMiA3LjM4NDYxQzEyIDcuNzI0MzEgMTEuNzExNSA4IDExLjM1NiA4QzExLjAwMDUgOCAxMC43MTIgNy43MjQzMSAxMC43MTIgNy4zODQ2MUMxMC43MTIgNS4wMDkyIDguNjg5NzggMy4wNzY4OCA2LjIwMzkgMy4wNzY4OFoiIGZpbGw9IiMxRTc5QzIiLz48L3N2Zz4=);

//
//  Common
//  --------------------------------------------


.amrev-comment-toolbar {
    & {
        display: flex;
        justify-content: none;
        align-items: center;
        flex-wrap: wrap;

        padding: $amrev__indent__xxl 0 0;
    }

    &.-nocomments {
        justify-content: flex-end;
    }

    .amrev-reply {
        border-right: 1px solid $amreview-line__color;
        box-sizing: border-box;
        flex-basis: 50%;
        order: 2;
        padding: 0 $amrev__indent__xxl 0 0;
    }

    .amrev-repbtn {
        & {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.1s all ease-in;
            background: $amrev-comments-btn__background;
            border: 1px solid $amrev-comments-btn__color;
            border-radius: 2px;
            box-shadow: none;
            box-sizing: border-box;
            color: $amrev-comments-btn__color;
            flex-basis: 100%;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 8px;
            position: relative;
            width: 100%;
        }

        &:before {
            transition: 0.1s all ease-in;
            align-content: center;
            align-items: center;
            background-image: $amrev-arrow-reply__background;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            display: flex;
            justify-content: center;
            height: 8px;
            margin: auto 5px;
            width: 12px;
        }

        &:hover,
        &:active,
        &.-active {
            & {
                background: $amrev-comments__active__color;
                border-color: $amrev-comments__active__color;
                color: $white;
            }

            &:before {
                filter: brightness(10);
            }
        }
    }

    .amrev-comment {
        display: flex;
        justify-content: center;
        align-items: center;

        align-self: stretch;
        box-sizing: border-box;
        flex-basis: 50%;
        order: 3;
        padding: 0 $amrev__indent__l;
        position: relative;
    }

    .amrev-combtn {
        & {
            color: $amrev-comments__active__color;
            font-size: 1.4rem;
            font-weight: 600;
        }

        &:hover {
            color: darken($amrev-comments__active__color, 10%);
        }

        &.-active:before {
            transform: rotate(180deg) translateY(1px);
        }

        &.-empty:before {
            display: none;
        }
    }
}

.amrev-comment-block {
    & {
        transition: 0.1s all ease-in;

        background: $amreview-placeholder__color;
        border-radius: 2px;
        margin: 0 -$amrev__indent__l 0 -$amrev__indent__l;
        padding: 0;
    }

    .amrev-title {
        border-bottom: 1px solid $amrev-comment__title__border-color;
        font-size: 12px;
        font-style: normal;
        margin: 0 0 $amrev__indent__xl 0;
        padding: $amrev__indent 0;
    }

    .amrev-qty {
        font-style: normal;
    }

    .amrev-message {
        padding: $amrev__indent__l $amrev__indent__xl;
    }
}

.amrev-comment-list {
    & {
        transition: 0.1s all ease-in;

        color: $black;
        display: none;
        padding: $amrev__indent__xl;
    }

    &.-active {
        display: block;
        margin: $amrev__indent__xl 0 0 0;
    }

    .amrev-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .amrev-comment {
        & {
            margin: 0;
            padding: 0;
        }

        &:not(:last-child) {
            padding: 0 0 $amrev__indent__l * 2;
        }
    }

    .amrev-name {
        margin: 0;
    }

    .amrev-email {
        font-size: 12px;
    }

    .amrev-date {
        display: block;
        font-size: 12px;
    }

    .amreview-text {
        display: block;
        font-size: 14px;
        padding: $amrev__indent__l 0 0 0;
    }
}

.amrev-comment-form {
    & {
        transition: 0.1s all ease-in;

        display: none;
        margin: $amrev__indent 0 0;
        padding: $amrev__indent__xl $amrev__indent__xl $amrev__indent__xxl;
    }

    &.-active {
        display: block;
    }

    .amrev-form {
        display: flex;
        justify-content: none;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    .amrev-fieldset {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        flex-basis: 100%;
        margin: 0 $amrev__indent__l 0 0;
    }

    .amrev-label {
        & {
            color: $amreview-color__grey-dark;
            font-size: 12px;
        }

        &.required:after {
            color: $amrev-comment__form__required-color;
            content: '*';
        }
    }

    .amrev-input {
        & {
            background: $white;
            border-color: transparent;
            color: $amreview-input-text__color;
            font-size: 12px;
            height: 30px;
        }

        ::placeholder {
            color: $amrev-checkbox__border-color;
        }

        &:hover,
        &:focus,
        &:active {
            border: 1px solid $amrev-comments-btn__color;
        }
    }

    .amrev-area {
        height: auto;
        padding: $amrev__indent__l;
    }

    .amrev-btn {
        & {
            transition: 0.1s all ease-in;

            background: $amrev-comments-btn__color;
            border: none;
            box-shadow: none;
            color: $white;
            font-size: 12px;
            margin: $amrev__indent__l auto 0 0;
            padding: $amrev__indent;
        }

        &:hover {
            background: darken($amrev-comments-btn__color, 5%);
        }
    }

    .amrev-field {
        position: relative;
    }

    .amrev-area-field {
        display: flex;
        justify-content: none;
        align-items: none;
        flex-wrap: wrap;

        flex-basis: 100%;
    }

    .amrev-input-field {
        flex-basis: 48%;
        margin: 0 0 $amrev__indent__l;
    }
}

.amreview-helpful-wrap {
    & {
        display: flex;
        justify-content: none;
        align-items: center;

        flex-basis: 100%;
        order: 1;
        padding: 0 0 $amrev__indent__xl 0;
    }

    > .amreview-helpful {
        color: $amreview-text__color;
        font-size: 14px;
    }

    > .amreview-options {
        margin-left: $amrev__indent__l;
    }

    .amreview-plus,
    .amreview-minus {
        &:focus {
            outline: 1px dotted $amreview-label__color;
            outline-offset: 3px;
        }
    }

    .amreview-plus {
        margin: 0 0 0 $amrev__indent;
    }

    > .amreview-options,
    .amreview-plus,
    .amreview-minus,
    .amreview-icon {
        cursor: pointer;
        display: inline-block;
        background: none;
        border: none;
    }

    .amreview-icon {
        height: 18px;
        margin: 0 0 0 $amrev__indent;
        transition: .3s;
        width: $amrev__indent__xl;
    }

    .amreview-plus > .amreview-icon {
        background: $amreview-like__background;
    }

    .amreview-plus:hover > .amreview-icon {
        background: $amreview-like__hover__background;
    }

    .amreview-plus.-voted > .amreview-icon {
        background: $amreview-like__active__background;
    }

    .amreview-minus > .amreview-icon {
        background: $amreview-dislike__background;
    }

    .amreview-minus:hover > .amreview-icon {
        background: $amreview-dislike__hover__background;
    }

    .amreview-minus.-voted > .amreview-icon {
        background: $amreview-dislike__active__background;
    }
}

//
//  Tablet +
//  --------------------------------------------

@media screen and (min-width: 768px) {
    .amrev-comment-block {
        margin: 0;
    }

    .amrev-area-field div.mage-error {
        margin-top: 4px;
        position: absolute;
        top: 100%;
    }

    .amrev-comment-form .amrev-fieldset {
        flex-basis: 70%;
    }

    .amrev-comment-toolbar {
        & {
            flex-wrap: nowrap;
            padding: $amrev__indent__xxl 0 $amrev__indent__xl;
        }

        .amreview-helpful-wrap {
            flex-basis: auto;
            order: 3;
            padding: 0 $amrev__indent__l;
        }

        .amreview-helpful-wrap .amreview-plus {
            margin: 0 $amrev__indent 0 0;
        }

        .amrev-comment {
            border-right: 1px solid $amreview-line__color;
            flex-basis: auto;
            justify-content: initial;
            margin: 0;
            order: 2;
        }

        .amrev-reply {
            flex-basis: auto;
            order: 1;
            padding: 0 $amrev__indent__l 0 0;
        }
    }

    .amreview-helpful-wrap .amreview-helpful {
        font-size: 12px;
    }
}
