// BBQ Galore variables

// BBQ Galore colors

$red                    : #dd2c00;
$bloody-red             : #cc4c00;
$yellow                 : #f9c000;
$green                  : #0e8a00;
$blue                   : #1976d2;
$blue-ocean             : #00cfff;

// Grayscale colors
$gray-darkest           : #212121;
$gray-dark              : #616161;
$gray                   : #9e9e9e;
$gray-light             : #e0e0e0;
$gray-lighter           : #eeeeee;
$gray-lightest          : #fafafa;

// Semantic color scheme
$color-primary          : $gray-darkest;
$color-secondary        : $bloody-red;
$color-success          : $green;
$color-info             : $yellow;
$color-warning          : $red;
$color-error            : $red;
$color-inverse          : $gray-dark;
$color-focus            : $red;

// Layout
$max-content-width      : 1440px;

//Border
$border-color-base      : $gray-light;
$border-color-secondary : $gray;
$border-width-tetriary  : 4px;

// Focus
$border-focus           : 1px solid $blue-ocean;
$border-focus-dark      : 1px solid $color-primary;

// Typography
$font-family-sans-serif : 'Roboto', sans-serif;

$font-family-base       : $font-family-sans-serif;
$font-family-secondary  : 'Roboto Slab', sans-serif;

$font-color-base        : $color-primary;
$font-weight-normal     : 400; //Roboto regular and Roboto Slab regular
$font-weight-semi-bold  : 600; //Roboto Slab semi bold
$font-weight-medium     : 700; //Roboto bold
$font-weight-bold       : 900; //Roboto black

//Shadow
$shadow                 : 0 8px 16px 0 rgba(0, 0, 0, 0.16);
