$filters__item-margin                : 0 !default;
$filters__item-margin\@large         : 0 0 64px 0 !default;

$filters__content-width\@medium      : 67% !default;
$filters__content-width\@large       : 100% !default;
$filters__content-margin\@medium     : $spacer--medium auto !default;
$filters__content-margin\@large      : 0 !default;

$filters__container-padding\@large   : 0 !default;

$filters__trigger-bg-color           : transparent !default;
$filters__trigger-padding            : $spacer--small $spacer--medium $spacer--small 0 !default;
$filters__trigger-padding\@medium    : $spacer--small $spacer--medium !default;
$filters__trigger-text-padding       : 0 !default;
$filters__trigger-text-color         : $color-primary !default;
$filters__trigger-icon-size--open    : 24px !default;
$filters__trigger-icon-fill--open    : $color-secondary !default;
$filters__trigger-border             : $border-base !default;
$filters__trigger-border-width       : 0 0 1px 0 !default;
$filters__trigger-text-transform     : uppercase !default;
$filters__trigger-font-weight        : $font-weight-bold !default;

$filters__heading-font-family        : $font-family-base !default;
$filters__heading-font-size          : $font-size-large !default;
$filters__heading-font-size\@medium  : $font-size-extra-large !default;
$filters__heading-font-weight        : $font-weight-bold !default;
$filters__heading-margin             : 0 !default;
$filters__heading-padding\@large     : $spacer $spacer $spacer 0 !default;
$filters__heading-padding\@xl        : $spacer $spacer--medium $spacer !default;

$filters__modal-top-padding          : 0 0 $spacer 0 !default;
$filters__modal-middle-padding       : $spacer--extra-large 0 !default;
$filters__modal-middle-padding\@large: 0 !default;
$filters__modal-top-padding\@large   : $filters__modal-top-padding !default;
$filters__border-width\@large        : 0 !default;
