.product-grid-item__rating {
    .product-reviews-summary.short {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .review-count {
            padding-left: 5px;
            font-size: 12px;
        }
    }
}