.button {
    letter-spacing: 1px;

    &--secondary {
        font-weight: $font-weight-medium;
    }

    &--icon-light {
        background-color: $gray-lighter;

        &:hover {
            &:before {
                display: none;
            }
        }
    }

    &--link {
        letter-spacing: normal;
    }
}
