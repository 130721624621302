.badge {
    letter-spacing: 1px;
    background-color: $green;

    &--clearance {
        background-color: $gray-darkest;
    }

    &--call-for-best-price {
        background-color: $bloody-red;
    }

    &--new {
        color: $gray-lightest;
        background-color: $blue;
    }
}
