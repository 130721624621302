$contact-bar__background                 : $color-primary;
$contact-bar__transition                 : none;

$contact-bar__link-color                 : $gray-lightest;
$contact-bar__link-font-weight           : $font-weight-normal;
$contact-bar__link-font-weight--icons    : $font-weight-bold;
$contact-bar__link-text-transform        : capitalize;
$contact-bar__link-text-transform--icons : uppercase;
$contact-bar__link-bg                    : none;
$contact-bar__link-hover-background-hover: $white;

$contact-bar__icon-fill                  : $gray-lightest;
$contact-bar__icon-fill-hover            : $color-primary;

@import 'contact-bar';
@import 'bbq-contact-bar';
