@import 'error';

div {
    &.required {
        > .checkbox__label {
            & > span {
                &:first-of-type {
                    &:after {
                        .newsletter__agreements & {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
