.header {
    &__logo {
        border-bottom: $border-base;
        margin-bottom: $spacer;

        @include mq($screen-m) {
            border: 0;
            margin: 0;
        }

        @include mq($screen-l) {
            margin-top: -$spacer;
        }
    }

    &__buttons {
        border-bottom: $border-base;
        margin-bottom: $spacer;

        @include mq($screen-m) {
            border: 0;
            margin: 0;
        }

        @include mq($screen-m) {
            width: auto;
            flex: 1 0 auto;
        }

        .header-button {
            width: $header__button-width;
            min-width: $header__button-min-width;
            padding: $header__button-padding;
            border-right: $header__button-border;

            @include mq($screen-s) {
                width: $header__button-width\@small;
                padding: $header__button-padding\@small;
            }

            @include mq($screen-m) {
                min-width: $header__button-min-width\@medium;
                width: $header__button-width\@medium;
                padding: $header__button-padding;
                margin: $header__button-margin\@medium;
            }

            @include mq($screen-l) {
                width: $header__button-width\@large;
                min-width: $header__button-min-width\@large;
                margin: $header__button-margin\@large;
                border: 0;
            }

            &--contact,
            &--stores {
                @include mq($screen-l) {
                    display: none;
                }
            }

            &--wishlist {
                display: none;

                @include mq($screen-s) {
                    display: inline-flex;
                }
            }
        }
    }

    &__minicart {
        @include mq($screen-l) {
            border-left: $header__button-border;
        }

        .button {
            border-right: 0;

            @include mq($screen-l) {
                margin: $header__minicart-button-margin\@large;
            }
        }
    }

    &__wrapper {
        @include mq($screen-m) {
            align-items: center;
        }
    }
}
