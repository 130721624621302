$icon__size      : 24px !default;
$icon__padding   : 0 !default;
$icon__transition: $transition-base !default;

.icon {
    display: block;
    width: $icon__size;
    height: $icon__size;
    padding: $icon__padding;
    transition: $icon__transition;
    pointer-events: none;
}
