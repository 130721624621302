//
//  Slick Slider Styles
//  ____________________________________________

//
//  Common
//  --------------------------------------------

//  Zoom PopUp custom styles
.amrev-fancybox-zoom .fancybox-arrow {
    & {
        box-shadow: none;
    }

    &:hover,
    &:active,
    &:focus {
        background: none;
        border: none;
        box-shadow: none;
        opacity: 1;
    }
}

.amreview-images.slick-slider {
    & {
        padding: 0 18px;
    }

    .slick-arrow {
        & {
            transition: 0.1s all ease-in;

            background-size: 7px 12px !important; // Slick Slider's styles override
            filter: invert(.5);
        }

        &:hover {
            filter: invert(1);
        }

        &.slick-next {
            right: 0;
        }

        &.slick-prev {
            left: 0;
            transform: rotate(180deg) translate(0, 50%);
        }
    }

    .slick-slide {
        & {
            border: 1px solid transparent;
            border-radius: 2px;
            box-sizing: border-box;
            text-align: center;
        }

        > div {
            display: flex;
        }
    }
}

.amreview-widget-container .slick-dots {
    position: initial;
}

//
//  Tablet +
//  --------------------------------------------
@media screen and (min-width: 768px) {
    .amreview-images.slick-slider {
        .slick-slide {
            display: inline-block;
        }

        .slick-arrow {
            background: $amreview-slider-arrow__background center no-repeat;
        }
    }
}

//
//  Mobile -
//  --------------------------------------------
@media screen and (max-width: 768px) {
    .amreview-widget-container .slick-dots button {
        border: 1px solid $color-gray_light;
    }
}
