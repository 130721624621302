@import 'more-info-variables';

.more-info {
    pointer-events: all;

    &[aria-hidden="true"] {
        display: none;
        opacity: 0;
    }

    &__trigger {
        background: $more-info__trigger-bg;
        justify-content: flex-start;
        padding: $more-info__trigger-padding;

        .button__text {
            margin: $more-info__trigger-text-margin;
            color: $more-info__trigger-color;
            font-size: $more-info__trigger-font-size;
            font-weight: $more-info__trigger-font-weight;
            text-decoration: $more-info__trigger-text-decoration;
            text-transform: $more-info__trigger-text-transform;
        }

        .button__icon {
            fill: $more-info__trigger-icon-fill;
            margin: $more-info__trigger-icon-margin;
        }
    }

    &__content-wrapper {
        position: absolute;
        width: 100%;
        padding: $more-info__content-wrapper-padding;
        background: $more-info__content-wrapper-bg;
        z-index: $more-info__content-wrapper-z-index;

        .more-info__trigger[aria-expanded="true"] + & {
            display: block;
            opacity: 1;
        }
        .more-info__trigger[aria-expanded="false"] + & {
            display: none;
            opacity: 0;
        }
    }

    &__content {
        position: relative;
    }

    &__close {
        position: absolute;
        top: $more-info__close-top;
        right: $more-info__close-right;
        background-color: $more-info__close-background;

        .icon {
            fill: $more-info__close-icon-fill;
        }
    }

    &__heading {
        color: $more-info__heading-color;
        font-family: $more-info__heading-font-family;
        font-weight: $more-info__heading-font-weight;
        text-transform: $more-info__heading-text-transform;
    }

    &__data {
        color: $more-info__content-color;

        p {
            color: $more-info__content-color;
        }
    }
}
