.ampromo {
    &__message {
        a {
            margin-left: 4px;
        }
    }

    &__item-link {
        min-height: auto;
    }

    &__items {
        .ampromo__item-details {
            padding: $spacer--medium 0 0;
        }
    }
}

.ampromo-items-content {
    .ampromo-popup-container {
        .ampromo-item {
            &:hover {
                background: none;
            }
        }
    }

    .ampromo-gallery {
        margin: 0;
    }
}
