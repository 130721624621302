// Magento default styles for elements by ID

#store-pickup {
    &.selected-shipping {
        ~ #store-selector {
            display: none;
        }
    }

    &.selected-store-pickup {
        ~ #shipping,
        ~ #opc-shipping_method {
            visibility: hidden;
            height: 0;
            overflow: hidden;
        }
    }
}

#opc-store-selector-popup {
    display: none;

    .modal-content & {
        display: block;
    }
}

#store-pickup-customer-email-fieldset {
    margin-bottom: $spacer--semi-large;

    .note {
        margin-top: $spacer;
    }
}

.store-selector-search {
    display: flex;
    align-items: flex-end;

    .search {
        flex: 1;
    }
}

.store-selector-popup-table {
    .row {
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}
