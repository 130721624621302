$product-view__short-description-heading-color      : $gray-dark;
$product-view__short-description-heading-font-weight: $font-weight-normal;
$product-view__stock-text-transform                 : none;

$product-view__title-font-size\@large               : $font-size-extra-large;

$product-view__sku-display                          : block;

$product-view__qty-margin                           : 0 0 $spacer--medium;

$product-view__rating-border                        : 0;
$product-view__rating-border-width                  : 0;
$product-view__rating-padding                       : 0;

$product-view__column-gap\@xl                       : 120px;
$product-view__wrapper-max-width                    : $max-content-width;

@import 'product-view';
@import 'bbq-product-view';
