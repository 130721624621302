//
//  Base Styles
//  ____________________________________________

//
//  Common
//  --------------------------------------------

.amreview-checkboxlabel a {
    text-decoration: underline;
}

.amrev-title-block {
    font-size: 2.4rem;
    font-weight: 700;
    margin: 0;
    padding: 50px 0 20px;
}

.amrev-items-block {
    position: relative;
}

.amreview-info-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -$amrev__indent__l;
    padding-top: $amrev__indent__xl;
}

.amreview-description-wrap {
    max-width: 100%;
    width: 100%;
}

.amrev-desc-title {
    & {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .amrev-voteqty {
        color: $amreview-text__color;
        font-size: 12px;
        line-height: $amrev__indent__xxl;
    }
}

.amreview-description {
    color: $black;
    font-size: 14px;
    line-height: 19px;
    margin-top: $amrev__indent__xl;
    overflow-wrap: break-word;
}

.amreview-summary-info {
    & {
        flex-basis: 100%;
        padding: 0 $amrev__indent;
    }

    .amreview-summary {
        color: $black;
        float: left;
        font-size: 60px;
        line-height: 1;
        margin-right: $amrev__indent__xl;
    }

    .amreview-count {
        color: $amreview-label__color;
        display: inline-block;
        font-size: 24px;
    }

    .amreview-showmore {
        color: $bloody-red;
        background: none;
        border: none;
        cursor: pointer;
        display: block;

        &:focus {
            outline: 1px dotted $amreview-label__color;
            outline-offset: 3px;
        }
    }
}

.amreview-percent-block {
    & {
        clear: both;
        color: $amreview-label__color;
        display: none;
        font-size: 24px;
    }

    .amreview-value {
        font-size: 30px;
        margin: 0 $amrev__indent 0 0;
    }

    .amreview-desc {
        font-size: 12px;
        max-width: 180px;
    }
}

.amreview-rating-wrapper {
    float: left;
    margin-bottom: 5px;
}

.amreview-review-list.-empty {
    padding: $amrev__indent__xl;
    position: relative;
    text-align: center;
}

.amreview-summary-details {
    clear: both;
    display: none;
    flex-basis: 100%;
    order: 3;
    padding: 40px $amrev__indent 0;
}

.amreview-summary-details .amreview-label {
    > .amreview-stars,
    > .amreview-bar,
    > .amreview-text {
        & {
            display: inline-block;
            line-height: 14px;
        }

        &:hover {
            color: $amreview-stars__filtering__color;
        }
    }

    > .amreview-stars {
        color: $amreview-label__color;
        margin-bottom: 5px;
        min-width: 50px;
        text-align: right;
    }

    .amreview-bar {
        background: $amreview-summary__bar__background;
        height: 6px;
        margin: 0 $amrev__indent__xl;
        vertical-align: middle;
        width: 50%;
    }

    .amreview-bar.-active {
        background: $amreview-review-bar__active__background;
        margin: 0;
    }
}

.amreview-add-new {
    & {
        background: $amreview-review-form__background;
        clear: both;
        flex-basis: 100%;
        margin: 40px 0 0;
        order: 2;
        padding: $amrev__indent__xl;
    }

    .amreview-button.action {
        border-radius: 2px;
        margin-right: 2%;
        padding: 12px 20px;
        background: $bloody-red;
        color: $white;
        font-size: 16px;
        text-decoration: none;
    }

    .amreview-label {
        color: $amreview-label__color;
        display: inline-block;
        line-height: 1.2;
        vertical-align: middle;
        width: 50%;
    }
}

.amreview-author-details {
    & {
        margin: $amrev__indent / 2 0 $amrev__indent__xl;
        position: relative;
        width: 100%;
    }

    .amreview-author {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 2px;
        word-break: break-all;
    }

    .amreview-date {
        color: $amreview-line__color;
        font-size: 12px;
        margin: 0 0 $amrev__indent__xxl 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .amreview-verified {
        color: $amreview-author__verified__color;
        font-size: 14px;
        margin: 0 0 2px;
    }

    .amreview-verified:after {
        background: $amreview-verified__background;
        content: '';
        display: inline-block;
        height: 12px;
        margin-left: 5px;
        vertical-align: middle;
        width: 12px;
    }

    .amreview-recommended {
        color: $amreview-recommended__color;
        font-size: 14px;
        margin-top: $amrev__indent / 2;
    }
}

//  Magento's styles override
.amreview-title-review {
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 5px;
    word-break: break-all;
}

.review__title {
    padding: 20px 0 10px;
}

.amreview-adminanswer-block {
    & {
        color: $amreview-text__color;
        padding: $amrev__indent__xxl 0 0 $amrev__indent__l;
    }

    > .amreview-title {
        color: $black;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
    }

    > .amreview-text {
        font-size: 14px;
    }
}

.amreview-review-wrapper {
    &.review-item {
        display: flex;
        justify-content: space-between;
        align-items: none;
        flex-wrap: wrap;
        padding: $amrev__indent__xxl 0 $amrev__indent__l * 2 0;
    }

    .amreview-images {
        display: flex;
        justify-content: none;
        align-items: none;
        flex-wrap: wrap;

        margin-left: -$amrev__indent / 2;
        margin-right: -$amrev__indent / 2;
        margin-top: $amrev__indent__xl;
    }

    .amreview-slider-item {
        box-sizing: content-box;
        display: inline-block;
        padding: $amrev__indent / 2;
        width: 100%;
    }

    .amreview-slider-item .amreview-image {
        height: 100%;
        margin: 0 auto;
        object-fit: cover;
        width: 100%;
    }
}

//  Override magento styles
.amreview-toolbar-wrap {
    .pages strong.page {
        background: $color-gray94;
        padding: 0 $amrev__indent;
    }

    .pages .action {
        background: transparent;
        box-shadow: none;
    }

    .limiter select {
        background-color: $white;
        box-shadow: none;
    }
}

.field.amreview-gdpr-wrap {
    padding: 0;
    position: relative;

    a {
        color: $amreview-link__color;
        cursor: pointer;
    }
}

.amreview-gdpr-wrap .mage-error[generated] {
    bottom: -30px;
    position: absolute;
}

.amreview-proscons-container {
    & {
        display: flex;
        flex-direction: column;
        margin: $amrev__indent__l 0 0 0;
        word-break: break-word;
        word-wrap: break-word;
    }

    .amreview-proscons {
        flex-basis: 50%;
        line-height: 15px;
        margin-right: $amrev__indent;
        min-width: 0;
        padding: 0 0 0 $amrev__indent__xl;
        position: relative;
    }

    .amreview-proscons:before {
        content: '';
        display: inline-block;
        height: 15px;
        left: 0;
        position: absolute;
        width: 15px;
    }

    .amreview-proscons.-dislike {
        color: $amreview-cons__color;
    }

    .amreview-proscons.-like {
        color: $amreview-pros__color;
    }

    .amreview-proscons.-dislike:before {
        background: $amreview-disadvantages__background;
        background-size: contain;
    }

    .amreview-proscons.-like:before {
        background: $amreview-advantages__background;
        background-size: contain;
    }
}

.field.amreview-email-wrap {
    margin: 0 0 $amrev__indent;
}

.amreview-summary-details {
    .amreview-label {
        color: unset;
        cursor: pointer;
        display: block;
        text-decoration: none;
    }
}

//  phpcs:ignore Magento2.Less.AvoidId.IdSelectorUsage
#row_amasty_advancedreview_reminder_frequency {
    .tooltip-content {
        ul {
            padding-left: $amrev__indent;
        }
    }
}

.block.review-list {
    display: flex;
    flex-direction: column;
}

.review__amount {
    letter-spacing: normal;
    span {
        padding-right: 5px;
    }
}

//
//  Mobile +
//  --------------------------------------------

@media screen and (min-width: 640px) {
    .amrev-title-block {
        & {
            display: flex;
            justify-content: none;
            align-items: center;
            display: flex;
            justify-content: none;
            align-items: center;
        }

        .am-strong {
            font-size: 2.4rem;
            font-weight: 400;
            padding: 0 24px 0 0;
            white-space: nowrap;
        }
    }

    .amreview-proscons-container {
        flex-direction: row;
    }
}

//
//  Tablet +
//  --------------------------------------------

@media screen and (min-width: 768px) {
    .amreview-info-wrapper {
        margin: 0;
    }

    .amreview-summary-info.amreview-showmore {
        display: none;
    }

    .amreview-review-list {
        & {
            margin: 0;
            padding: 50px 0 $amrev__indent__xl 0;
        }

        &.-empty {
            padding: $amrev__indent__xl * 2;
        }
    }

    .amrev-desc-title {
        flex-wrap: nowrap;
    }

    .amreview-author-details {
        margin: $amrev__indent 0 $amrev__indent__xl;
    }

    .amreview-percent-block {
        display: flex;
        justify-content: none;
        align-items: center;
    }

    .amreview-summary-info {
        display: inline-block;
    }

    .amreview-add-new {
        & {
            margin: $amrev__indent__xl * 2 0 0;
        }

        .amreview-label {
            max-width: 160px;
            text-align: left;
        }
    }

    .amreview-author-details {
        & {
            display: inline-block;
            margin: 0 auto 0 0;
            vertical-align: top;
            width: 25%;
        }

        .amreview-verified {
            display: block;
        }

        .amreview-date {
            position: static;
        }

        .amreview-recommended {
            background: $amreview-recommended__background;
            border-left: 2px solid $amreview-author__recommended__border-color;
            color: $amreview-author__recommended__color;
            font-size: 12px;
            line-height: 14px;
            margin: $amrev__indent__xl 0;
            max-width: 78px;
            padding: 4px 4px 4px 6px;
            position: relative;
        }
    }

    .amreview-recommended:after {
        border: 18px solid transparent;
        border-left: 14px solid $amreview-recommended__background;
        bottom: 0;
        content: '';
        display: block;
        position: absolute;
        right: -32px;
        top: 0;
    }

    .amreview-toolbar-wrap {
        &.review-toolbar .pages {
            border: 0;
            float: right;
            padding: $amrev__indent__xl 0;
        }

        &.review-toolbar .limiter {
            float: left;
            padding: $amrev__indent__xl 0;
        }
    }

    .amreview-review-wrapper {
        &.review-item {
            padding: $amrev__indent__l * 2 0 $amrev__indent__xl * 2 0;
        }
    }

    .amreview-description-wrap {
        display: inline-block;
        position: relative;
        width: 75%;
    }
}

//
//  Desktop +
//  --------------------------------------------

@media screen and (min-width: 1024px) {
    .amreview-summary-details {
        & {
            flex-basis: auto;
            margin-left: $amrev__indent__xl;
            order: initial;
            padding: 0;
        }

        .amreview-label .amreview-bar {
            width: 80px;
        }
    }

    .amreview-summary-info {
        flex-basis: auto;
        order: initial;
    }

    .amreview-description-wrap {
        width: 75%;
    }

    .amreview-title-review {
        margin-right: 280px;
    }

    .amreview-customer-review {
        margin-bottom: 8px;
    }

    .amreview-add-new {
        flex-basis: auto;
        order: initial;
    }

    .amreview-info-wrapper {
        .amreview-summary-info,
        .amreview-summary-details,
        .amreview-add-new {
            vertical-align: middle;
        }

        .amreview-summary-details {
            border-right: 2px solid $amreview-line__color;
            margin: 0 auto 0 0;
            padding: 0 $amrev__indent__l 0 $amrev__indent__xxl;
        }

        .amreview-add-new {
            background: none;
            display: inline-block;
            margin: 0 0 0 $amrev__indent__xxl;
            padding-right: 0;
        }
    }
}
