$cookie-message__width                  : 100% !default;
$cookie-message__background-color       : $gray-lighter !default;
$cookie-message__font-size              : $font-size-small !default;
$cookie-message__font-family            : $font-family-base !default;
$cookie-message__line-height            : 16px !default;
$cookie-message__padding                : $spacer--medium $spacer $spacer--medium $spacer--large !default;
$cookie-message__container-margin       : 0 auto !default;
$cookie-message__container-max-wdith    : $max-content-width !default;
$cookie-message__container-wdith        : 100% !default;

$cookie-message__text-color             : $color-secondary !default;
$cookie-message__text-padding           : 0 !default;
$cookie-message__text-margin            : 0 $spacer--medium 0 0 !default;
$cookie-message__text-letter-spacing    : 1px !default;

$cookie-message__button-color           : $color-secondary !default;
$cookie-message__button-height          : 48px !default;
$cookie-message__button-font-weight     : $font-weight-normal !default;
$cookie-message__button-padding         : 0 !default;
$cookie-message__button-margin          : 0 !default;
$cookie-message__button-background      : none !default;
$cookie-message__button-border          : 0 !default;
$cookie-message__button-text-transform  : none !default;

$cookie-message__button-icon-fill       : $color-secondary !default;
$cookie-message__button-icon-size       : 24px !default;

//actions
$cookie-message__padding--actions       : $spacer--medium $spacer--large !default;
$cookie-message__button-padding--actions: $button__padding !default;
