$forgot-password__max-width                    : 432px !default;
$forgot-password__margin-bottom                : 80px !default;

$forgot-password__field-margin-bottom          : $spacer--large !default;

$forgot-password__captcha-wrapper-margin-bottom: $spacer--medium !default;
$forgot-password__captcha-bottom-padding       : 0 $spacer--medium !default;

$forgot-password__image-width                  : auto !default;
$forgot-password__image-max-width              : 250px !default;
$forgot-password__image-padding-right          : $spacer !default;

$forgot-password__heading-margin               : $spacer--semi-medium 0 64px !default;

$forgot-password__actions-padding              : $spacer--medium 0 !default;

$forgot-password__info-margin-bottom           : $spacer--large !default;
$forgot-password__info-color                   : $color-secondary !default;
$forgot-password__info-font-size               : $font-size-medium !default;
