.catalog-product-view {
    .ampickupmsi {
        &-locations-container {
            .ampickupmsi-location {
                cursor: default;
                &:hover {
                    background: inherit;
                }
            }
        }
    }
}
