$product-grid-item__details-margin\@xl: $spacer--medium;

.product-grid-item {
    &--slider-item {
        .product-grid-item__link {
            flex: auto;
        }

        .product-grid-item__details {
            padding-top: $spacer--large;
        }
    }

    &__stock-status {
        @extend .product-view__stock;
    }

    &__free-shipping {
        width: 100%;
        color: $color-secondary;
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        line-height: 24px;
    }

    &__custom-stock-status {
        width: 100%;
        margin: 0 0 $spacer;
        color: $gray-dark;
        font-size: $font-size-small;

        p {
            margin: 0;
            color: $gray-dark;
            font-size: $font-size-small;
        }
    }

    &__details {
        flex-grow: 1;

        @include mq($screen-xl) {
            margin: $product-grid-item__details-margin\@xl;
        }
    }

    &__rating {
        margin: $spacer 0;
    }

    &__name {
        flex-grow: 1;
    }

    &__link {
        flex-grow: 0;
        min-height: 225px;

        @include mq($screen-s) {
            min-height: 370px;
        }

        @include mq($screen-m) {
            min-height: 325px;
        }
    }

    &__image-wrapper {
        .image {
            max-height: 325px;
        }
    }

    &__price {
        .price__value,
        .price__value:not(.price__value--between) {
            .recently-viewed & {
                flex-direction: column-reverse;
            }
        }

        .price-from-to {
            display: flex;
            flex-direction: column;

            .price-to {
                display: flex;
                flex-direction: column-reverse;
            }

            .price__text {
                display: none;
            }
        }

        .price-minimal-from {
            .price__text {
                display: inline-block;
                min-width: 40px;
            }
        }

        .price-minimal-from + .price-to {
            .price__text {
                display: inline-block;
                min-width: 40px;
            }
        }

        .price-container.price-msrp {
            text-decoration: line-through;
        }
    }

    &__actions {
        .tocompare {
            display: flex;
        }
    }
}
