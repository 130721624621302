$datepicker__border                              : 2px solid $black !default;
$datepicker__background-color                    : $white !default;

$datepicker__arrow-top                           : 9px !default;

$datepicker__prev-icon-border-color              : transparent $black transparent transparent !default;
$datepicker__next-icon-border-color              : transparent transparent transparent $black !default;

$datepicker__select-background-color             : transparent !default;
$datepicker__select-height                       : 35px !default;
$datepicker__select-margin-right                 : $spacer !default;

$datepicker__calendar-border-collapse            : separate !default;
$datepicker__calendar-border                     : 0 !default;

$datepicker__today-background-color              : $black !default;
$datepicker__today-background-color--active      : $white !default;
$datepicker__today-color                         : $white !default;
$datepicker__today-color--active                 : $black !default;

$datepicker__current-day-border                  : 1px solid $black !default;
$datepicker__current-day-background-color        : $white !default;
$datepicker__current-day-background-color--active: $black !default;
$datepicker__current-day-color--active           : $white !default;

$datepicker__ui-state-text-align                 : center !default;
$datepicker__ui-state-background-color--active   : $black !default;
$datepicker__ui-state-color--active              : #fff !default;

$datepicker__title-display                       : flex !default;
$datepicker__title-justify-content               : center !default;
$datepicker__title-align-items                   : center !default;
$datepicker__title-flex-direction                : row-reverse !default;
