$product-list-item__padding                      : $spacer--semi-medium $spacer $spacer--medium;
$product-list-item__padding\@medium              : $spacer--medium;
$product-list-item__border-width                 : 1px;

$product-list-item__sku-font-size\@medium        : $font-size-small;
$product-list-item__sku-color                    : $gray-dark;
$product-list-item__list-color                   : $gray-dark;
$product-list-item__description-color            : $gray-dark;
$product-list-item__description-font-size\@medium: $font-size-small;

$product-list-item__stock-text-transform         : none;
$product-list-item__stock-text-decoration-hover  : none;

$product-list-item__action-background            : $gray-lighter;

@import 'product-list-item';
@import 'bbq-product-list-item';
