$footer__links-width\@large                    : 75%;
$footer__links-dropdown-list-item-width\@medium: 25%;

.footer {
    &__social-handler {
        &:before {
            background: $gray-dark;
        }

        @include mq($screen-l) {
            width: 25%;
        }
    }

    &__social-list {
        .button--icon {
            background-color: $color-primary;
            border: 2px solid $gray-dark;

            &:hover,
            &.focus-visible {
                .icon {
                    fill: $color-focus;
                }
            }

            .icon {
                fill: $gray-lightest;
            }
        }
    }

    &__handler {
        border-bottom: $border-width-base $border-style-base $gray-dark;
    }

    &__links {
        @include mq($screen-l) {
            width: $footer__links-width\@large;
        }

        .dropdown-list {
            &__item {
                @include mq($screen-m) {
                    width: $footer__links-dropdown-list-item-width\@medium;
                }
            }
        }

        .dropdown-list--with-breakpoint {
            .dropdown-list__label {
                &:hover,
                &.focus-visible {
                    color: $gray-lightest;
                }
            }
        }
    }

    &__wrapper {
        @include mq($screen-l) {
            display: flex;
            width: 100%;
        }
    }

    &__address {
        position: relative;
        color: $gray-lightest;

        @include mq($screen-l) {
            width: 25%;
            padding: $spacer--extra-large 0 $spacer--medium $spacer--large;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: $spacer--semi-medium;
                width: 1px;
                height: 90%;
                background: $gray-dark;
            }
        }
    }

    &__address-content {
        margin: 0 0 $spacer--large;
        font-size: $font-size-medium;
        font-style: normal;

        @include mq($screen-l) {
            margin: 0 0 $spacer--extra-large;
        }
    }

    &__address-title {
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
    }

    &__address-subtitle {
        margin: 0;
        font-weight: $font-weight-normal;
    }

    &__address-title,
    &__address-subtitle,
    &__address-phone,
    &__address-opening-info {
        color: $gray-lightest;
    }

    &__address-phone {
        display: flex;
        font-size: $font-size-extra-large;
        font-weight: $font-weight-bold;
        line-height: 48px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        text-decoration: none;
    }

    &__address-opening-info {
        font-size: $font-size-medium;
    }
}
