.webforms {
    .form {
        .actions-toolbar,
        .fieldset,
        .field {
            padding: 0;
            float: none;
        }
    }

    .webforms-field {
        float: none;
    }

    &__modal-container {
        overflow: auto;
    }

    &__modal-content {
        padding-top: $spacer--semi-medium;
    }
}
