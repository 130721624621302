$logo__max-width             : 188px;
$logo__max-width\@medium     : 194px;
$logo__max-width\@large      : 240px;
$logo__max-width\@extra-large: 240px;
$logo__image-height          : auto;
$logo__image-height\@medium  : auto;
$logo__image-width\@medium   : auto;

.logo {
    display: flex;
    align-items: center;
    flex: 1 $logo__max-width;
    justify-content: center;
    max-width: $logo__max-width;

    @include mq($screen-l) {
        justify-content: flex-start;
        max-width: $logo__max-width\@large;
    }

    @include mq($screen-xl) {
        max-width: $logo__max-width\@extra-large;
    }

    &__image {
        height: $logo__image-height;

        @include mq($screen-m) {
            height: $logo__image-height\@medium;
            width: $logo__image-width\@medium;
        }
    }

    // specsheet page
    .page-print & {
        position: relative;
        margin: 0 auto $spacer--medium;
    }
}
