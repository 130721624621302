@import 'dashboard-variables';

.dashboard {
    &__actions {
        justify-content: space-between;
    }

    &__handler {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: $dashboard__handler-margin;
    }

    &__status {
        display: inline-block;
        padding: $dashboard__status-padding;
        border: $dashboard__status-border;
        border-radius: $dashboard__status-border-radius;
        font-size: $dashboard__status-font-size;
        vertical-align: middle;
    }
    
    &__order-date {
        span {
            font-weight: $dashboard__order-date-font-weight;
        }
    }

    &__form {
        padding: $dashboard__form-padding;

        &:first-child {
            padding-left: $dashboard__form-padding-left--first-child;
        }

        &:last-child {
            padding-right: $dashboard__form-padding-right--last-child;
        }
    }

    &__product {
        padding: $dashboard__product-padding;
        border: $dashboard__product-border;
    }

    &__form {
        &--hidden {
            display: none;
        }
    }

    &__sidebar {
        @include mq($screen-l) {
            border: $dashboard__sidebar-border;
            border-width: $dashboard__sidebar-border-width;
            padding: $dashboard__sidebar-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $dashboard__sidebar-padding\@extra-large;
        }
    }

    &__content {
        @include mq($screen-l) {
            padding: $dashboard__content-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $dashboard__content-padding\@extra-large;
        }
    }

    &__content-heading {
        margin: $dashboard__content-heading-padding;

        &--with-status {
            display: inline-block;
            margin: 0 $spacer--large 0 0;
            vertical-align: middle;
        }
    }

    &__title,
    &__title--wrapped,
    &__subtitle  {
        font-family: $dashboard__title-font-family;
    }

    &__title,
    &__title--wrapped {
        font-size: $dashboard__title-font-size;
        font-weight:  $dashboard__title-font-weight;
    }

    &__title {
        margin: $dashboard__title-margin;
        padding: $dashboard__title-padding;
        background: $dashboard__title-background;
        border: $dashboard__title-border;
        border-width: $dashboard__title-border-width;
    }

    &__subtitle {
        font-size:  $dashboard__subtitle-font-size;
    }

    &__link {
        display: inline-flex;
    }
}
