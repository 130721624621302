//
//  Checkbox Styles
//  ____________________________________________

$amrev-checkbox-icon__background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgOSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy45MDQ4IDBMMy41NDI1IDQuNjU5OEwxLjA5NDk1IDIuMDQ2MDNMMCAzLjIxNTg1TDMuNTQyNSA3TDkgMS4xNjk4M0w3LjkwNDggMFoiIGZpbGw9IiM1MTQ5NDMiLz48L3N2Zz4=);
$amrev-checkbox__border-color: $color-gray68;
$amrev-checkbox__active__border-color: $amrev-toolbar__active__color;

//
//  Common
//  --------------------------------------------

.amrev-checkbox-container {
    .amrev-label {
        & {
            display: flex;
            justify-content: center;
            align-items: center;

            justify-content: flex-start;
            padding: 0 0 0 $amrev__indent__xl;
            position: relative;
        }

        &:hover:before {
            border-color: $amrev-checkbox__active__border-color;
            box-shadow: 0 1px 2px rgba(37, 83, 119, .25);
        }

        &:before,
        &:after {
            transition: 0.1s all ease-in;

            content: '';
            cursor: pointer;
            display: block;
            left: 0;
            position: absolute;
        }

        &:after {
            align-content: center;
            align-items: center;
            background-image: $amrev-checkbox-icon__background;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            display: flex;
            justify-content: center;

            bottom: 0;
            height: 8px;
            margin: auto 0 auto 3px;
            opacity: 0;
            top: 0;
            width: 10px;
        }

        &:before {
            background-color: $white;
            border: 1px solid $amrev-checkbox__border-color;
            border-radius: 2px;
            box-sizing: border-box;
            height: 15px;
            width: 15px;
        }
    }

    .amrev-input:checked {
        + .amrev-label:after {
            opacity: 1;
        }

        + .amrev-label:before {
            border-color: $amrev-checkbox__active__border-color;
        }

        + .amrev-label:before:focus {
            box-shadow: 0 1px 2px rgba(37, 83, 119, .25);
        }
    }

    .amrev-input {
        display: none;
    }
}
