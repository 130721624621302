.success-page {
    padding: 60px 0 48px;

    &__order-confirmation {
        padding: $spacer--large 0;
    }

    &__button {
        width: 100%;
        @include mq($screen-s) {
            width: auto;
        }
    }
}
