//prevent document from scrolling
.no-scroll {
    overflow: hidden;
    height: 100%;
}

// margin
.margin-0 {
    margin: 0;
}
.margin-xs {
    margin: $spacer;
}
.margin-s {
    margin: $spacer--small;
}
.margin-m {
    margin: $spacer--medium;
}
.margin-sm {
    margin: $spacer--semi-medium;
}
.margin-l {
    margin: $spacer--large;
}
.margin-sl {
    margin: $spacer--semi-large;
}
.margin-xl {
    margin: $spacer--extra-large;
}
.margin-auto {
    margin: auto;
}

// margin-top
.margin-top-0 {
    margin-top: 0;
}
.margin-top-xs {
    margin-top: $spacer;
}
.margin-top-s {
    margin-top: $spacer--small;
}
.margin-top-m {
    margin-top: $spacer--medium;
}
.margin-top-sm {
    margin-top: $spacer--semi-medium;
}
.margin-top-l {
    margin-top: $spacer--large;
}
.margin-top-sl {
    margin-top: $spacer--semi-large;
}
.margin-top-xl {
    margin-top: $spacer--extra-large;
}
.margin-top-auto {
    margin-top: auto;
}

// margin-bottom
.margin-bottom-0 {
    margin-bottom: 0;
}
.margin-bottom-xs {
    margin-bottom: $spacer;
}
.margin-bottom-s {
    margin-bottom: $spacer--small;
}
.margin-bottom-m {
    margin-bottom: $spacer--medium;
}
.margin-bottom-sm {
    margin-bottom: $spacer--semi-medium;
}
.margin-bottom-l {
    margin-bottom: $spacer--large;
}
.margin-bottom-sl {
    margin-bottom: $spacer--semi-large;
}
.margin-bottom-xl {
    margin-bottom: $spacer--extra-large;
}
.margin-bottom-auto {
    margin-bottom: auto;
}

// margin-left
.margin-left-0 {
    margin-left: 0;
}
.margin-left-xs {
    margin-left: $spacer;
}
.margin-left-s {
    margin-left: $spacer--small;
}
.margin-left-m {
    margin-left: $spacer--medium;
}
.margin-left-sm {
    margin-left: $spacer--semi-medium;
}
.margin-left-l {
    margin-left: $spacer--large;
}
.margin-left-sl {
    margin-left: $spacer--semi-large;
}
.margin-left-xl {
    margin-left: $spacer--extra-large;
}
.margin-left-auto {
    margin-left: auto;
}

// margin-right
.margin-right-0 {
    margin-right: 0;
}
.margin-right-xs {
    margin-right: $spacer;
}
.margin-right-s {
    margin-right: $spacer--small;
}
.margin-right-m {
    margin-right: $spacer--medium;
}
.margin-right-sm {
    margin-right: $spacer--semi-medium;
}
.margin-right-l {
    margin-right: $spacer--large;
}
.margin-right-sl {
    margin-right: $spacer--semi-large;
}
.margin-right-xl {
    margin-right: $spacer--extra-large;
}
.margin-right-auto {
    margin-right: auto;
}

// margin horizontal
.margin-hz-0 {
    margin-left: 0;
    margin-right: 0;
}
.margin-hz-xs {
    margin-left: $spacer;
    margin-right: $spacer;
}
.margin-hz-s {
    margin-left: $spacer--small;
    margin-right: $spacer--small;
}
.margin-hz-m {
    margin-left: $spacer--medium;
    margin-right: $spacer--medium;
}
.margin-hz-sm {
    margin-left: $spacer--semi-medium;
    margin-right: $spacer--semi-medium;
}
.margin-hz-l {
    margin-left: $spacer--large;
    margin-right: $spacer--large;
}
.margin-hz-sl {
    margin-left: $spacer--semi-large;
    margin-right: $spacer--semi-large;
}
.margin-hz-xl {
    margin-left: $spacer--extra-large;
    margin-right: $spacer--extra-large;
}
.margin-hz-auto {
    margin-left: auto;
    margin-right: auto;
}

// margin vertical
.margin-vc-0 {
    margin-top: 0;
    margin-bottom: 0;
}
.margin-vc-xs {
    margin-top: $spacer;
    margin-bottom: $spacer;
}
.margin-vc-s {
    margin-top: $spacer--small;
    margin-bottom: $spacer--small;
}
.margin-vc-m {
    margin-top: $spacer--medium;
    margin-bottom: $spacer--medium;
}
.margin-vc-sm {
    margin-top: $spacer--semi-medium;
    margin-bottom: $spacer--semi-medium;
}
.margin-vc-l {
    margin-top: $spacer--large;
    margin-bottom: $spacer--large;
}
.margin-vc-sl {
    margin-top: $spacer--semi-large;
    margin-bottom: $spacer--semi-large;
}
.margin-vc-xl {
    margin-top: $spacer--extra-large;
    margin-bottom: $spacer--extra-large;
}
.margin-vc-auto {
    margin-top: auto;
    margin-bottom: auto;
}

// padding
.padding-0 {
    padding: 0;
}
.padding-xs {
    padding: $spacer;
}
.padding-s {
    padding: $spacer--small;
}
.padding-m {
    padding: $spacer--medium;
}
.padding-sm {
    padding: $spacer--semi-medium;
}
.padding-l {
    padding: $spacer--large;
}
.padding-sl {
    padding: $spacer--semi-large;
}
.padding-xl {
    padding: $spacer--extra-large;
}

// padding-top
.padding-top-0 {
    padding-top: 0;
}
.padding-top-xs {
    padding-top: $spacer;
}
.padding-top-s {
    padding-top: $spacer--small;
}
.padding-top-m {
    padding-top: $spacer--medium;
}
.padding-top-sm {
    padding-top: $spacer--semi-medium;
}
.padding-top-l {
    padding-top: $spacer--large;
}
.padding-top-sl {
    padding-top: $spacer--semi-large;
}
.padding-top-xl {
    padding-top: $spacer--extra-large;
}

// padding-bottom
.padding-bottom-0 {
    padding-bottom: 0;
}
.padding-bottom-xs {
    padding-bottom: $spacer;
}
.padding-bottom-s {
    padding-bottom: $spacer--small;
}
.padding-bottom-m {
    padding-bottom: $spacer--medium;
}
.padding-bottom-sm {
    padding-bottom: $spacer--semi-medium;
}
.padding-bottom-l {
    padding-bottom: $spacer--large;
}
.padding-bottom-sl {
    padding-bottom: $spacer--semi-large;
}
.padding-bottom-xl {
    padding-bottom: $spacer--extra-large;
}

// padding-left
.padding-left-0 {
    padding-left: 0;
}
.padding-left-xs {
    padding-left: $spacer;
}
.padding-left-s {
    padding-left: $spacer--small;
}
.padding-left-m {
    padding-left: $spacer--medium;
}
.padding-left-sm {
    padding-left: $spacer--semi-medium;
}
.padding-left-l {
    padding-left: $spacer--large;
}
.padding-left-sl {
    padding-left: $spacer--semi-large;
}
.padding-left-xl {
    padding-left: $spacer--extra-large;
}

// padding-right
.padding-right-0 {
    padding-right: 0;
}
.padding-right-xs {
    padding-right: $spacer;
}
.padding-right-s {
    padding-right: $spacer--small;
}
.padding-right-m {
    padding-right: $spacer--medium;
}
.padding-right-sm {
    padding-right: $spacer--semi-medium;
}
.padding-right-l {
    padding-right: $spacer--large;
}
.padding-right-sl {
    padding-right: $spacer--semi-large;
}
.padding-right-xl {
    padding-right: $spacer--extra-large;
}

// padding horizontal
.padding-hz-0 {
    padding-left: 0;
    padding-right: 0;
}
.padding-hz-xs {
    padding-left: $spacer;
    padding-right: $spacer;
}
.padding-hz-s {
    padding-left: $spacer--small;
    padding-right: $spacer--small;
}
.padding-hz-m {
    padding-left: $spacer--medium;
    padding-right: $spacer--medium;
}
.padding-hz-sm {
    padding-left: $spacer--semi-medium;
    padding-right: $spacer--semi-medium;
}
.padding-hz-l {
    padding-left: $spacer--large;
    padding-right: $spacer--large;
}
.padding-hz-sl {
    padding-left: $spacer--semi-large;
    padding-right: $spacer--semi-large;
}
.padding-hz-xl {
    padding-left: $spacer--extra-large;
    padding-right: $spacer--extra-large;
}

// padding vertical
.padding-vc-0 {
    padding-top: 0;
    padding-bottom: 0;
}
.padding-vc-xs {
    padding-top: $spacer;
    padding-bottom: $spacer;
}
.padding-vc-s {
    padding-top: $spacer--small;
    padding-bottom: $spacer--small;
}
.padding-vc-m {
    padding-top: $spacer--medium;
    padding-bottom: $spacer--medium;
}
.padding-vc-sm {
    padding-top: $spacer--semi-medium;
    padding-bottom: $spacer--semi-medium;
}
.padding-vc-l {
    padding-top: $spacer--large;
    padding-bottom: $spacer--large;
}
.padding-vc-sl {
    padding-top: $spacer--semi-large;
    padding-bottom: $spacer--semi-large;
}
.padding-vc-xl {
    padding-top: $spacer--extra-large;
    padding-bottom: $spacer--extra-large;
}

// borders
.border-primary {
    border: $border-base;
}
.border-secondary {
    border: $border-secondary;
}
.border-color-primary {
    border-color: $color-primary;
}
.border-color-secondary {
    border-color: $color-secondary;
}
.border-color-light {
    border-color: $gray;
}
.border-width-1 {
    border-width: 1px;
}
.border-width-2 {
    border-width: 2px;
}

// text alignment
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

// text transform
.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.none-text-transform {
    text-transform: none;
}

// font
.bold {
    font-weight: $font-weight-bold;
}

// display none
.display-none {
    display: none;
}

.display-none-s {
    @include mq($max-screen: $screen-s - 1) {
        display: none;
    }
}

.display-none-m {
    @include mq($max-screen: $screen-m - 1) {
        display: none;
    }
}

.display-none-l {
    @include mq($max-screen: $screen-l - 1) {
        display: none;
    }
}

.display-none-xl {
    @include mq($max-screen: $screen-xl - 1) {
        display: none;
    }
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

// width
.width-full {
    width: 100%;
}

.width-full-screen {
    width: 100vw;
}

.width-auto {
    width: auto;
}

.width-initial {
    width: initial;
}

.visually-hidden {
    @include visually-hidden();
}
